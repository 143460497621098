var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "d-flex flex-column",
    staticStyle: {
      "flex": "1 0 auto"
    }
  }, [_c('v-row', {
    staticClass: "ma-0"
  }, [_c('v-col', _vm._b({
    staticStyle: {
      "width": "110px"
    },
    attrs: {
      "cols": "auto"
    }
  }, 'v-col', _vm.$attrs.props.headerProps, false), [_vm._v(_vm._s(_vm.term) + " "), _vm.required ? _c('span', {
    staticClass: "pink--text"
  }, [_vm._v("(필수)")]) : _vm._e()]), _c('v-col', {
    staticStyle: {
      "white-space": "pre-line"
    }
  }, [_vm._t("default")], 2)], 1), _c('v-divider')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }