<template>
    <v-container class="display" fluid fill-height>
        <v-layout justify-center>
            <v-responsive max-width="1280px" width="100%">
                <div class="headline text-start mt-8">전시관리</div>

                <v-tabs v-model="tabIndex" background-color="content">
                    <v-tab>섹션1</v-tab>
                    <v-tab>섹션2</v-tab>
                    <v-tab>섹션3</v-tab>
                    <v-tab>섹션4</v-tab>
                </v-tabs>

                <v-tabs-items v-model="tabIndex" touchless>
                    <v-tab-item v-for="code in codes" :key="code">
                        <draggable v-model="displays.filter[code]" @input="drag" class="d-flex flex-wrap">
                            <v-col v-for="display in displays.filter[code]" :key="display._id" cols="6" sm="3">
                                <v-card height="30vh" class="d-flex flex-column" style="overflow:hidden">
                                    <v-card-text class="pa-0" style="height:90%">
                                        <v-img :src="`/res/shop/products/${display?.product?._id}/${display?.product?.thumb}`" cover height="80%" />
                                        <div class="pa-2 pb-0 font-weight-bold text-truncate">{{ display?.product?.name || "-" }}</div>
                                        <div class="px-2">
                                            <span v-if="display.startDate">{{ display?.startDate?.toDate?.() || "" }}</span> ~ <span v-if="display.endDate">{{ display?.endDate?.toDate?.() || "" }}</span>
                                        </div>
                                    </v-card-text>
                                    <v-card-actions class="pt-0">
                                        <v-spacer />
                                        <v-icon small class="mr-2" @click="edit(display)">mdi-pencil</v-icon>
                                        <v-icon small class="mr-2" @click="remove(display)">mdi-delete</v-icon>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </draggable>
                        <v-card v-if="!displays.filter[code].length" class="mt-4">
                            <v-card-text class="text-center">등록된 데이터가 없습니다</v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>

                <v-btn @click="create" color="primary" fab fixed bottom right>
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-dialog v-model="dialog" max-width="700">
                    <v-card v-if="editItem">
                        <v-card-title class="subtitle-1">전시상품등록</v-card-title>
                        <v-card-text>
                            <product-autocomplete v-if="!editItem._id" v-model="editItem.product" dense hide-details class="mt-2" @input="(product) => (editItem._product = product._id)"></product-autocomplete>
                            <v-row class="mt-7">
                                <v-col cols="12" md="6" class="py-0">
                                    <v-date-field v-model="editItem.startDate" label="전시시작일자" dense hide-details clearable></v-date-field>
                                </v-col>
                                <v-col cols="12" md="6" class="py-0">
                                    <v-date-field v-model="editItem.endDate" label="전시종료일자" dense hide-details clearable></v-date-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer />
                            <v-btn text color="grey" @click="close()">취소</v-btn>
                            <v-btn text color="primary" @click="save()">저장</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-responsive>
        </v-layout>
    </v-container>
</template>

<script>
import api from "@/api";

import draggable from "vuedraggable";

import ProductAutocomplete from "@/components/console/shop/products/product-autocomplete.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

export default {
    components: {
        draggable,
        ProductAutocomplete,
        VDateField,
    },
    mounted() {
        this.init();
    },
    data() {
        return {
            tabIndex: 0,
            codes: ["section1", "section2", "section3", "section4"],

            dialog: false,

            displays: {
                filter: {
                    section1: [],
                    section2: [],
                    section3: [],
                    section4: [],
                },
            },

            editItem: null,
            defaultItem: {
                _id: null,
                _product: null,
                startDate: null,
                endDate: null,
            },
        };
    },
    methods: {
        async init() {
            this.search(false);
        },
        async search() {
            try {
                var { displays } = await api.console.shop.displays.gets();

                this.displays = {
                    filter: {
                        section1: displays.filter((display) => display.code == "section1"),
                        section2: displays.filter((display) => display.code == "section2"),
                        section3: displays.filter((display) => display.code == "section3"),
                        section4: displays.filter((display) => display.code == "section4"),
                    },
                };
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        create() {
            this.editItem = Object.assign(
                {},
                {
                    code: this.codes[this.tabIndex],
                    sequence: +this.displays.filter[this.codes[this.tabIndex]].length,
                    ...this.defaultItem,
                }
            );
            this.dialog = true;
        },
        close() {
            this.editItem = null;
            this.dialog = false;
        },
        edit(display) {
            this.editItem = { ...display };
            this.dialog = true;
        },
        async drag(displays) {
            try {
                for (var display of displays) {
                    await api.console.shop.displays.put({ _id: display._id, sequence: displays.indexOf(display) });
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async save() {
            try {
                !this.editItem._id ? await api.console.shop.displays.post(this.editItem) : await api.console.shop.displays.put(this.editItem);
                alert("저장되었습니다");
                this.search();
                this.close();
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        async remove(display) {
            try {
                if (confirm("전시목록에서 삭제하시겠습니까?")) {
                    await api.console.shop.displays.delete(display);
                    alert("삭제되었습니다");
                    this.search();
                    this.close();
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>

<style>
.display .theme--light.v-tabs-items {
    background-color: transparent !important;
}
</style>
