<template>
    <v-card class="mt-4">
        <v-card-title>
            <v-row>
                <v-col cols="auto" class="subtitle-1 py-0">특정 등급 회원노출 (사용/미사용)</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="py-0">
                    <v-switch v-model="form.isGrade" :value="true" dense hide-details class="mt-0" @change="emit"></v-switch>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text v-if="form.isGrade">
            <v-select v-model="form._grade" multiple :items="grades" item-text="name" item-value="_id" placeholder="회원 등급을 선택해 주세요." dense hide-details class="pa-0 ma-0" @input="emit" />
        </v-card-text>
    </v-card>
</template>

<script>
import api from "@/api";

const initForm = (form = {}) => ({
    ...form,
    isGrade: form?.isGrade || false,
});

export default {
    props: {
        value: { type: Object, default: initForm },
    },
    data: () => ({
        form: initForm(),
        grades: []
    }),
    mounted() {
        this.sync();
    },
    methods: {
        async sync() {
            this.form = initForm(this.value);
            var { levels } = await api.console.levels.getLevels();
            this.grades = levels;

        },
        emit() {
            const { isGrade, _grade } = this.form;
            this.$emit("input", initForm({ ...this.value, isGrade, _grade }));
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep {
    .text-align-end-input input {
        text-align: end;
    }
}
</style>
