<template>
    <list-search v-bind="{ showsSearch }" @search="setQuery">
        <v-row align="center">
            <v-col cols="12" sm="6" md="4" lg="">
                <v-select label="검색대상" v-model="query.searchKey" :items="searchKeys" placeholder="전체" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="">
                <v-text-field label="검색어" v-model="query.searchValue" clearable v-bind="attrs_input" @keydown.enter="setQuery" />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="">
                <v-select label="회원유형" v-model="query.type" :items="types" placeholder="전체" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="">
                <v-text-field label="주의회원" :value="query.isWarning ? '주의회원' : '일반회원'" v-bind="attrs_input" readonly @click="query.isWarning = !query.isWarning">
                    <template #prepend-inner> <v-switch v-model="query.isWarning" dense inset hide-details class="mt-0 mr-n2 mb-n1 pt-0" /> </template>
                </v-text-field>
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { attrs_input, attrs_switch, USER_TYPES } from "@/assets/variables";

import ListSearch from "@/components/console/dumb/list-search.vue";

let initQuery = (query = {}) => ({
    ...query,

    searchKey: query?.searchKey || null,
    searchValue: query?.searchValue || null,

    type: query?.type || null,
    isWarning: query?.isWarning || null,
});

const types = Object.values(USER_TYPES);

const searchKeys = [
    { text: "이름", value: "name" },
    { text: "연락처", value: "phone" },
    { text: "이메일", value: "email" },
];

export default {
    components: {
        ListSearch,
    },
    props: {
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),

        types,
        searchKeys,

        attrs_input,
        attrs_switch,
    }),
    mounted() {
        this.sync();
    },

    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        setQuery() {
            const { ...query } = this.query || {};

            if (!query?.searchKey) delete query.searchKey;
            if (!query?.searchValue) delete query.searchValue;

            if (!query?.type) delete query.type;
            if (!query?.isWarning) delete query.isWarning;

            query.page = 1;

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
