<template>
    <v-layout justify-center>
        <v-responsive max-width="1024px" width="100%">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">브랜드 목록</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showsSearch = !showsSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showsSearch" class="elevation-1 mx-auto">
                    <v-card-text class="pt-4 pb-0">
                        <v-container class="pt-0 pb-0">
                            <v-row>
                                <v-col class="pt-0 pb-0" cols="12" sm="12" md="6">
                                    <v-text-field v-model="filter.name" label="브랜드명" persistent-placeholder name="filter.username" type="text" />
                                </v-col>
                                <v-col class="pt-0 pb-0" cols="12" sm="12" md="6">
                                    <v-select v-model="filter.display" label="사용여부" persistent-placeholder></v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="pt-0">
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="search"><v-icon class="mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-data-table :headers="brandsHeaders" :items="brands" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                <template v-slot:item.createdAt="{ item }">
                    {{ item.createdAt.toDate() }}
                </template>
                <template v-slot:item.brand="{ item }">
                    <v-row align="center">
                        <v-col cols="auto" class="py-0"><v-img :src="`/res/shop/brands/${item._id}/${item.image}`" width="56" max-height="56"></v-img></v-col>
                        <v-col cols="auto" class="py-0">{{ item.name }}</v-col>
                    </v-row>
                </template>
                <template v-slot:item.actions="{item}">
                    <v-icon small @click="edit(item)">mdi-pencil</v-icon>
                    <v-icon small @click="remove(item)" class="ml-1">mdi-delete</v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" class="mt-4 mb-12" :total-visible="11" @input="search"></v-pagination>

            <v-btn bottom color="accent" dark fab fixed right @click="create">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import ShopCategorySelect from '@/components/console/shop/categories/shop-category-select.vue';

export default {
    components: {
        ShopCategorySelect
    },
    data: () => {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showsSearch: false,
            filter: {
                name: "",
                display: null,
            },

            categories: [],

            ///////////////////////////////////////////////
            // 주문목록 테이블 정보
            ///////////////////////////////////////////////
            page: 1,
            pageCount: 0,
            limit: 10,

            brands: [],
            brandsHeaders: [{
                text: '순서',
                align: 'center',
                width: 140,
                value: 'sequence'
            }, {
                text: '브랜드',
                align: 'center',
                value: 'brand'
            }, {
                text: '코드',
                align: 'center',
                width: 140,
                value: 'code'
            }, {
                text: '사용여부',
                align: 'center',
                width: 100,
                value: 'display'
            }, {
                text: '등록일자',
                width: 160,
                align: 'center',
                value: 'createdAt'
            }, {
                text: 'Actions',
                width: 200,
                align: 'center',
                value: 'actions'
            }],
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                await this.search();
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        async search(){
            try{
                var { summary, brands } = await api.console.shop.brands.getBrands({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit
                    }
                });

                this.brands = brands;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        create(){
            this.$router.push({
                path: `${this.$route.path}/create`
            });
        },

        edit(brand){
            this.$router.push({
                path: `${this.$route.path}/${brand._id}`
            });
        },

        async remove(brand){
            try{
                if(confirm("브랜드를 삭제하시겠습니까?")){
                    await api.console.shop.brands.deleteBrand(brand);
                    await this.search();

                    alert("삭제되었습니다");
                }
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },
    }
}
</script>
