var render = function render(){
  var _vm$bonusPrice, _vm$bonusPrice$format;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "subtitle-1 py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("보너스적립 (사용/미사용)")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "value": true,
      "dense": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.form.bonusEnabled,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bonusEnabled", $$v);
      },
      expression: "form.bonusEnabled"
    }
  })], 1)], 1)], 1), _vm.form.bonusEnabled ? _c('v-card-text', [_c('v-currency-field', {
    staticClass: "text-align-end-input ma-0",
    attrs: {
      "max": 100,
      "label": "적립퍼센트",
      "suffix": "%",
      "persistent-placeholder": "",
      "persistent-hint": "",
      "hint": `적립금액: ${(_vm$bonusPrice = _vm.bonusPrice) === null || _vm$bonusPrice === void 0 ? void 0 : (_vm$bonusPrice$format = _vm$bonusPrice.format) === null || _vm$bonusPrice$format === void 0 ? void 0 : _vm$bonusPrice$format.call(_vm$bonusPrice)}원`
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.form.bonus.amount,
      callback: function ($$v) {
        _vm.$set(_vm.form.bonus, "amount", $$v);
      },
      expression: "form.bonus.amount"
    }
  })], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }