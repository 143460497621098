<template>
    <v-data-table v-bind="{ items, headers }" mobile-breakpoint="10000" disable-pagination disable-sort hide-default-footer class="v-sheet--outlined" style="flex: 1 0 auto; position: relative">
        <template #top> <div class="table-label px-1 white">기본정보</div> </template>
        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
    </v-data-table>
</template>

<script>
const headers = [
    { text: "주문번호", value: "orderNo" },
    { text: "주문일시", value: "createdAt", formatter: (value) => value?.toDateTime?.() },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), cellClass: "caption", divider: index != array.length - 1 }));

export default {
    props: {
        order: { type: Object, default: () => {} },
    },
    data: () => ({
        headers,
    }),
    computed: {
        items() {
            return [this.order];
        },
    },
};
</script>

<style lang="scss" scoped>
.table-label {
    position: absolute;
    left: 0;
    bottom: 100%;
    transform: translateY(50%) scale(0.75);

    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
}
::v-deep {
    .v-data-table__mobile-row {
        padding: 4px 16px !important;
        &,
        & * {
            height: auto !important;
            min-height: auto;
            font-size: 12px;
            font-weight: normal;
            white-space: pre-line;
        }
        &:first-child {
            margin-top: 12px;
        }
        &:last-child {
            margin-bottom: 12px;
        }
    }
}
</style>
