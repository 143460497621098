var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "100%",
      "max-width": "320",
      "content-class": "rounded-0"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on,
          shows: _vm.shows
        })];
      }
    }], null, true)
  }, [_c('v-card', {
    attrs: {
      "tile": ""
    }
  }, [_c('v-data-table', _vm._b({
    attrs: {
      "mobile-breakpoint": "10000",
      "disable-pagination": "",
      "disable-sort": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c('v-card-subtitle', {
          staticClass: "mb-n4"
        }, [_vm._v(" 배송정보 ")])];
      },
      proxy: true
    }, _vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref2) {
          var value = _ref2.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.delivery.number`,
      fn: function (_ref3) {
        var _item$delivery;
        var item = _ref3.item,
          value = _ref3.value;
        return [item !== null && item !== void 0 && (_item$delivery = item.delivery) !== null && _item$delivery !== void 0 && _item$delivery.url ? [_c('v-btn', {
          staticClass: "mr-n4",
          attrs: {
            "text": "",
            "href": `${item.delivery.url}${value}`,
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s(value) + " ")])] : [_vm._v(" " + _vm._s(value) + " ")]];
      }
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers
  }, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }