<template>
    <v-list-item v-bind="{ to, target }" :draggable="false">
        <v-list-item-avatar class="my-0 mr-1 ml-n1">
            <image-popup
                :src="`/res/shop/products/${product?._id}/${product?.thumb}`"
                :attrs_avatar="{
                    size: '30',
                    rounded: true,
                }"
            />
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-subtitle class="caption text-truncate">
                <span> {{ product.category?.name }} </span>
            </v-list-item-subtitle>
            <v-list-item-title class="caption text-truncate">
                <span> {{ product.name || placeholder || "-" }} </span>
            </v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
import { initProduct } from "@/assets/variables";

import ImagePopup from "@/components/console/dumb/image-popup.vue";

export default {
    components: {
        ImagePopup,
    },
    props: {
        product: { type: Object, default: initProduct },

        isLink: { type: Boolean, default: false },
        target: { type: String, default: undefined },
        placeholder: { type: String, default: undefined },
    },
    computed: {
        to() {
            if (this.isLink && this.product._id) {
                return `/console/shop/products/${this.product._id}`;
            }
        },
    },
};
</script>

<style></style>
