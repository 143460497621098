<template>
    <v-sheet class="d-flex flex-column" style="flex: 1 0 auto">
        <v-row class="ma-0">
            <v-col cols="auto" style="width: 110px" v-bind="$attrs.props.headerProps">{{ term }} <span v-if="required" class="pink--text">(필수)</span> </v-col>
            <v-col style="white-space: pre-line"> <slot /> </v-col>
        </v-row>
        <v-divider />
    </v-sheet>
</template>

<script>
export default {
    props: {
        term: String,
        required: Boolean,
    },
};
</script>
