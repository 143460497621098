<template>
    <v-data-table v-bind="{ items: value, headers }" hide-default-footer disable-filtering disable-pagination disable-sort :items-per-page="-1" class="rounded-0 transparent">
        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
        <template #[`item.type`]="{ item }">
            <span> {{ GRADE_BENEFITS_TYPES[item.type]?.text || item.type || "-" }} </span>
            <span v-if="item.value">{{ item.value }}%</span>
        </template>
        <template #[`item.isActive`]="{ item }">
            <v-switch v-model="item.isActive" v-bind="attrs_switch" disabled color="grey darken-1" />
        </template>
    </v-data-table>
</template>

<script>
import { attrs_switch, GRADE_BENEFITS_TYPES } from "@/assets/variables";

const headers = [
    { width: 140, value: "type", text: "유형", formatter: (value) => GRADE_BENEFITS_TYPES[value]?.text || value || "-" },
    { width: +80, value: "isActive", text: "활성화", align: "center" },
].map((item) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-") }));

export default {
    props: {
        value: { type: Array, default: () => [] }, // benefits
    },
    data: () => ({
        headers,
        attrs_switch,
        GRADE_BENEFITS_TYPES,
    }),
};
</script>

<style></style>
