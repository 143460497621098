var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-product-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("회원 상세")]), _c('v-card', {
    staticClass: "mt-3",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("회원정보")]), _c('v-card-text', [_c('v-row', {
    staticClass: "mx-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "이름"
    },
    model: {
      value: _vm.user.name,
      callback: function ($$v) {
        _vm.$set(_vm.user, "name", $$v);
      },
      expression: "user.name"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "연락처"
    },
    model: {
      value: _vm.user.phone,
      callback: function ($$v) {
        _vm.$set(_vm.user, "phone", $$v);
      },
      expression: "user.phone"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1)], 1), _c('v-row', {
    staticClass: "mx-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "이메일"
    },
    model: {
      value: _vm.user.email,
      callback: function ($$v) {
        _vm.$set(_vm.user, "email", $$v);
      },
      expression: "user.email"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "회원등급",
      "items": _vm.levels,
      "item-text": "name",
      "item-value": "_id"
    },
    model: {
      value: _vm.user._level,
      callback: function ($$v) {
        _vm.$set(_vm.user, "_level", $$v);
      },
      expression: "user._level"
    }
  }, 'v-select', _vm.attrs_input, false))], 1)], 1), _c('v-row', {
    staticClass: "mx-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "비밀번호"
    },
    model: {
      value: _vm.user.password,
      callback: function ($$v) {
        _vm.$set(_vm.user, "password", $$v);
      },
      expression: "user.password"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1)], 1), _c('v-row', {
    staticClass: "mx-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "우편번호"
    },
    model: {
      value: _vm.user.postcode,
      callback: function ($$v) {
        _vm.$set(_vm.user, "postcode", $$v);
      },
      expression: "user.postcode"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1)], 1), _c('v-row', {
    staticClass: "mx-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "기본주소"
    },
    model: {
      value: _vm.user.address1,
      callback: function ($$v) {
        _vm.$set(_vm.user, "address1", $$v);
      },
      expression: "user.address1"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "상세주소"
    },
    model: {
      value: _vm.user.address2,
      callback: function ($$v) {
        _vm.$set(_vm.user, "address2", $$v);
      },
      expression: "user.address2"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-3",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0 subtitle-1",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("주의회원")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', _vm._b({
    attrs: {
      "color": "red lighten-1"
    },
    model: {
      value: _vm.user.isWarning,
      callback: function ($$v) {
        _vm.$set(_vm.user, "isWarning", $$v);
      },
      expression: "user.isWarning"
    }
  }, 'v-switch', _vm.attrs_switch, false))], 1)], 1)], 1)], 1), _c('v-data-table', _vm._b({
    staticClass: "v-sheet--outlined mt-3",
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c('v-card-title', {
          staticClass: "subtitle-2 font-weight-bold"
        }, [_vm._v("주문내역")]), _c('v-divider')];
      },
      proxy: true
    }, _vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.numbers`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', [_c('order-view', {
          attrs: {
            "_order": item._order
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref3) {
              var _item$order;
              var attrs = _ref3.attrs,
                on = _ref3.on;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "pa-0 caption",
                attrs: {
                  "x-small": "",
                  "text": "",
                  "tile": ""
                }
              }, 'v-btn', attrs, false), on), [_vm._v(_vm._s((_item$order = item.order) === null || _item$order === void 0 ? void 0 : _item$order.orderNo))])];
            }
          }], null, true)
        })], 1), _c('div', [_vm._v(_vm._s(item.purchaseNo))])];
      }
    }, {
      key: `item.order.sender`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('div', [_vm._v(_vm._s(item.order.sender.name))]), _c('div', [_vm._v(_vm._s(item.order.sender.phone))]), _c('div', [_vm._v(_vm._s(item.order.sender.email))])];
      }
    }, {
      key: `item.order.receiver`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('div', [_vm._v(_vm._s(item.order.receiver.name))]), _c('div', [_vm._v(_vm._s(item.order.receiver.phone))]), _c('div', [_vm._v(_vm._s(item.order.receiver.email))])];
      }
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers
  }, false)), _c('v-row', {
    staticClass: "mt-4",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }