var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-product-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [!_vm.$route.params._product ? _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("상품 등록")]) : _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("상품 상세")]), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("카테고리")]), _c('v-card-text', [_c('shop-category-select', {
    attrs: {
      "categories": _vm.categories
    },
    on: {
      "input": function ($event) {
        _vm.product._category = _vm.product.category._id;
      }
    },
    model: {
      value: _vm.product.category,
      callback: function ($$v) {
        _vm.$set(_vm.product, "category", $$v);
      },
      expression: "product.category"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7",
      "sm": "7"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("썸네일")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.thumb || _vm.product.thumb ? _c('v-img', {
    attrs: {
      "src": _vm.thumb ? _vm.createObjectURL(_vm.thumb) : `/res/shop/products/${_vm.$route.params._product}/${_vm.product.thumb}`,
      "width": "128",
      "height": "128"
    }
  }) : _c('v-responsive', {
    attrs: {
      "width": "128",
      "height": "128"
    }
  }, [_c('v-row', {
    staticClass: "fill-height ma-0 grey lighten-3",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-file-input', {
    attrs: {
      "label": "썸네일 업로드",
      "show-size": "",
      "accept": "image/*"
    },
    model: {
      value: _vm.thumb,
      callback: function ($$v) {
        _vm.thumb = $$v;
      },
      expression: "thumb"
    }
  })], 1), _vm.thumb || _vm.product.thumb ? _c('v-col', {
    staticClass: "py-0 pl-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.removeThumb
    }
  }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)], 1) : _vm._e()], 1), _c('v-row')], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("상품 이미지")]), _c('v-card-text', [_c('draggable', {
    model: {
      value: _vm.images,
      callback: function ($$v) {
        _vm.images = $$v;
      },
      expression: "images"
    }
  }, [_c('transition-group', _vm._l(_vm.images, function (i, index) {
    return _c('v-row', {
      key: `image-${index}`,
      staticClass: "pt-0 pb-0",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "pt-4 pb-0",
      attrs: {
        "cols": "auto"
      }
    }, [_vm.images[index] ? _c('v-img', {
      attrs: {
        "src": _vm.createObjectURL(_vm.images[index]),
        "width": "128",
        "height": "128"
      }
    }) : _c('v-responsive', {
      attrs: {
        "width": "128",
        "height": "128"
      }
    }, [_c('v-row', {
      staticClass: "fill-height ma-0 grey lighten-3",
      attrs: {
        "align": "center",
        "justify": "center"
      }
    }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', [_c('v-file-input', {
      attrs: {
        "label": '슬라이드' + (+index + 1),
        "show-size": "",
        "dense": "",
        "accept": "image/*"
      },
      model: {
        value: _vm.images[index],
        callback: function ($$v) {
          _vm.$set(_vm.images, index, $$v);
        },
        expression: "images[index]"
      }
    })], 1), _vm.images[index] || _vm.product.images[index] ? _c('v-col', {
      staticClass: "py-0 pl-0",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function ($event) {
          return _vm.removeImage(index);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)], 1) : _vm._e()], 1);
  }), 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5",
      "sm": "5"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("상품 정보")]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "상품명",
      "persistent-placeholder": ""
    },
    model: {
      value: _vm.product.name,
      callback: function ($$v) {
        _vm.$set(_vm.product, "name", $$v);
      },
      expression: "product.name"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "판매가 (0원 일때 '무료'로 문구변경)",
      "persistent-placeholder": ""
    },
    model: {
      value: _vm.product.price,
      callback: function ($$v) {
        _vm.$set(_vm.product, "price", $$v);
      },
      expression: "product.price"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "재고",
      "persistent-placeholder": "",
      "disabled": _vm.product.optionsEnabled
    },
    model: {
      value: _vm.product.stock,
      callback: function ($$v) {
        _vm.$set(_vm.product, "stock", $$v);
      },
      expression: "product.stock"
    }
  }), _c('v-text-field', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      "label": "원가 (0원 이상시 할인율도 같이표시)",
      "persistent-placeholder": ""
    },
    model: {
      value: _vm.product.cost,
      callback: function ($$v) {
        _vm.$set(_vm.product, "cost", $$v);
      },
      expression: "product.cost"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "진열순서",
      "persistent-placeholder": ""
    },
    model: {
      value: _vm.product.sequence,
      callback: function ($$v) {
        _vm.$set(_vm.product, "sequence", $$v);
      },
      expression: "product.sequence"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "제조사",
      "persistent-placeholder": ""
    },
    model: {
      value: _vm.product.manufacturer,
      callback: function ($$v) {
        _vm.$set(_vm.product, "manufacturer", $$v);
      },
      expression: "product.manufacturer"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "원산지",
      "persistent-placeholder": ""
    },
    model: {
      value: _vm.product.origin,
      callback: function ($$v) {
        _vm.$set(_vm.product, "origin", $$v);
      },
      expression: "product.origin"
    }
  }), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "해시태그",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        (_vm.product.hashtags.push(_vm.hashtag) || true) && (_vm.hashtag = null);
      }
    },
    model: {
      value: _vm.hashtag,
      callback: function ($$v) {
        _vm.hashtag = $$v;
      },
      expression: "hashtag"
    }
  })], 1), _c('v-col', {
    staticClass: "pl-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "small": ""
    },
    on: {
      "click": function ($event) {
        _vm.product.hashtags.push(_vm.hashtag);
        _vm.hashtag = null;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-tag")]), _vm._v("추가")], 1)], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, _vm._l(_vm.product.hashtags || [], function (tag, index) {
    return _c('v-chip', {
      key: index,
      staticClass: "mr-1",
      attrs: {
        "close": "",
        "close-icon": "mdi-delete"
      },
      on: {
        "click:close": function ($event) {
          return _vm.product.hashtags.splice(index, 1);
        }
      }
    }, [_vm._v(_vm._s(tag))]);
  }), 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "subtitle-1 py-0"
  }, [_vm._v("전시여부 (전시/숨김)")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.product.display,
      callback: function ($$v) {
        _vm.$set(_vm.product, "display", $$v);
      },
      expression: "product.display"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "subtitle-1 py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("판매여부 (판매/중단)")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.product.sale,
      callback: function ($$v) {
        _vm.$set(_vm.product, "sale", $$v);
      },
      expression: "product.sale"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "subtitle-1 py-0"
  }, [_vm._v("신상품 (사용/미사용)")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.product.newly,
      callback: function ($$v) {
        _vm.$set(_vm.product, "newly", $$v);
      },
      expression: "product.newly"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "subtitle-1 py-0"
  }, [_vm._v("베스트 (사용/미사용)")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.product.best,
      callback: function ($$v) {
        _vm.$set(_vm.product, "best", $$v);
      },
      expression: "product.best"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "subtitle-1 py-0"
  }, [_vm._v("품절여부 (품절/판매)")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.product.soldout,
      callback: function ($$v) {
        _vm.$set(_vm.product, "soldout", $$v);
      },
      expression: "product.soldout"
    }
  })], 1)], 1)], 1)], 1), _c('shop-product-grade', {
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }), _c('shop-product-bonus', {
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  })], 1)], 1), _c('shop-product-discount', {
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }), _c('shop-product-options', {
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }), _c('shop-product-supplies', {
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }), _c('shop-product-shippings', {
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }), _c('shop-product-islands', {
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }), _c('shop-product-seo', {
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }), _c('shop-product-regular-delivery', {
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }), _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("상품 상세 설명")]), _c('v-card-text', [_c('naver-smarteditor', {
    attrs: {
      "rows": "10"
    },
    model: {
      value: _vm.product.content,
      callback: function ($$v) {
        _vm.$set(_vm.product, "content", $$v);
      },
      expression: "product.content"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }