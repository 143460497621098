var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "720px",
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("브랜드 등록")]), _c('v-row', {
    staticClass: "mt-4",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "algin": ""
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("썸네일")]), _c('v-card-text', [_c('v-row', [_vm.image || _vm.brand.image ? _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.image ? _vm.createObjectURL(_vm.image) : `/res/shop/brands/${_vm.brand._id}/${_vm.brand.image}`,
      "max-width": "128",
      "max-height": "128"
    }
  })], 1) : _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": "128",
      "height": "128"
    }
  }, [_c('v-row', {
    staticClass: "fill-height ma-0 grey lighten-3",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-file-input', {
    attrs: {
      "label": "썸네일 업로드",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "show-size": ""
    },
    model: {
      value: _vm.image,
      callback: function ($$v) {
        _vm.image = $$v;
      },
      expression: "image"
    }
  })], 1)], 1), _c('v-row')], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-text', [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "브랜드명",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.brand.name,
      callback: function ($$v) {
        _vm.$set(_vm.brand, "name", $$v);
      },
      expression: "brand.name"
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "브랜드코드",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.brand.code,
      callback: function ($$v) {
        _vm.$set(_vm.brand, "code", $$v);
      },
      expression: "brand.code"
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "순서",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.brand.sequence,
      callback: function ($$v) {
        _vm.$set(_vm.brand, "sequence", $$v);
      },
      expression: "brand.sequence"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "subtitle-1 py-0"
  }, [_vm._v("사용여부(사용/미사용)")]), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.brand.display,
      callback: function ($$v) {
        _vm.$set(_vm.brand, "display", $$v);
      },
      expression: "brand.display"
    }
  })], 1)], 1)], 1)], 1), _c('v-row', {
    staticClass: "mt-6",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }