<template>
    <v-container fluid fill-height>
        <v-layout justify-center>
            <v-responsive max-width="1280px" width="100%">
                <v-row class="mt-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">1:1 문의</v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="pt-0 pb-0">
                        <v-btn class="ma-2" color="secondary" rounded @click="showsSearch = !showsSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                    </v-col>
                </v-row>

                <v-expand-transition>
                    <v-card v-show="showsSearch" class="elevation-1 mx-auto">
                        <v-card-text>
                            <v-row class="mt-3">
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-menu ref="createdAts0" :close-on-content-click="false" :return-value.sync="filter.createdAts[0]" transition="scale-transition" offset-y min-width="290px">
                                        <template #activator="{ on }">
                                            <v-text-field v-model="filter.createdAts[0]" label="등록일자" persistent-placeholder dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="filter.createdAts[0]" no-title scrollable @input="$refs.createdAts0.save(filter.createdAts[0])"></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col cols="12" sm="6" class="py-0">
                                    <v-menu ref="createdAts1" :close-on-content-click="false" :return-value.sync="filter.createdAts[1]" transition="scale-transition" offset-y min-width="290px">
                                        <template #activator="{ on }">
                                            <v-text-field v-model="filter.createdAts[1]" label="등록일자" persistent-placeholder dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="filter.createdAts[1]" no-title scrollable @input="$refs.createdAts1.save(filter.createdAts[1])"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row class="mt-6">
                                <v-col class="pt-0 pb-0" cols="12" sm="6" md="3">
                                    <v-select v-model="filter.searchKey" :items="filterItems" label="상세조건" persistent-placeholder dense hide-details item-text="text" item-valuie="value"></v-select>
                                </v-col>
                                <v-col class="pt-0 pb-0" cols="12" sm="6" md="3">
                                    <v-text-field v-model="filter.searchValue" :disabled="filter.searchKey == null" dense hide-details></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" rounded text @click="search" dense><v-icon class="mr-2">mdi-magnify</v-icon>검색</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-expand-transition>

                <v-data-table v-bind="{ items, headers }" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                    <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>

                    <template #[`item.actions`]="{ item }">
                        <v-icon small @click="edit(item)">mdi-pencil</v-icon>
                    </template>
                </v-data-table>
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12"></v-pagination>

                <v-dialog v-model="questionDialog" max-width="720px">
                    <v-card v-if="questionDialog">
                        <v-card-title>
                            <span class="subtitle-1">상품문의</span>
                        </v-card-title>
                        <v-card-text>
                            <v-text-field v-model="editItem.subject" label="문의 제목" persistent-placeholder readonly dense hide-details class="mt-3"></v-text-field>
                            <v-text-field label="문의날짜" persistent-placeholder readonly dense hide-details :value="editItem.createdAt.toDate()" class="mt-6"></v-text-field>
                            <v-textarea v-model="editItem.content" label="문의 내용" persistent-placeholder readonly dense hide-details class="mt-6"></v-textarea>
                            <v-textarea v-model="editItem.reply" label="답변" persistent-placeholder dense hide-details class="mt-6"></v-textarea>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="close">취소</v-btn>
                            <v-btn color="primary" text @click="save">저장</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-responsive>
        </v-layout>
    </v-container>
</template>

<script>
import api from "@/api";
import { USER_TYPES } from "@/assets/variables";

const headers = [
    { width: 80, text: "위치", value: "code", formatter: (key) => USER_TYPES[key]?.text },
    { width: 160, text: "유형", value: "type" },
    { width: 200, text: "작성자", value: "user", formatter: ({ name, username } = {}) => (name && username ? `${name}(${username})` : username || name || "-") },
    { text: "문의내용", value: "subject" },
    { width: 110, text: "작성일자", value: "createdAt", formatter: (value) => value?.toDate?.() || value || "-" },
    { width: 110, text: "답변상태", value: "reply", formatter: (value) => (value ? "답변완료" : "미답변") },
    { width: 100, text: "Actions", value: "actions" },
].map((item) => ({ ...item, formatter: item?.formatter || ((value) => value || "-") }));

export default {
    data: () => {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showsSearch: true,

            filter: {
                createdAts: ["", ""],
                searchKey: "",
                searchValue: "",
            },

            filterItems: [
                { text: "전체", value: "" },
                { text: "회원아이디", value: "user.username" },
            ],

            ///////////////////////////////////////////////
            // 상품문의 테이블 정보
            ///////////////////////////////////////////////
            page: 1,
            pageCount: 0,
            limit: 20,

            questions: [],
            headers,

            ///////////////////////////////////////////////////
            //
            ///////////////////////////////////////////////////
            questionDialog: false,
            editItem: {},
        };
    },
    computed: {
        items() {
            return this.questions;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.search();
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        async search() {
            try {
                var { summary, questions } = await api.console.center.questions.getQuestions({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                });

                this.questions = questions;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        edit(question) {
            this.editItem = Object.assign({}, question);
            this.questionDialog = true;
        },

        close() {
            this.questionDialog = false;
        },

        async save() {
            try {
                await api.console.center.questions.putQuestion(this.editItem);
                await this.search();
                alert("저장되었습니다");
                this.questionDialog = false;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },
    },
};
</script>
