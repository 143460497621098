var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.value ? _c('v-card', {
    staticClass: "mt-6 shop-product-supplies"
  }, [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "subtitle-1 py-0"
  }, [_vm._v("정기배송 (사용/미사용)")]), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.input
    },
    model: {
      value: _vm.regularDeliveryEnabled,
      callback: function ($$v) {
        _vm.regularDeliveryEnabled = $$v;
      },
      expression: "regularDeliveryEnabled"
    }
  })], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }