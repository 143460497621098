var render = function render(){
  var _vm$item;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.readonly ? _c('span', [_vm._v(" " + _vm._s(((_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : _vm$item.name) || "-") + " ")]) : _c('v-autocomplete', _vm._b({
    on: {
      "click": _vm.alert
    },
    model: {
      value: _vm.$data._user,
      callback: function ($$v) {
        _vm.$set(_vm.$data, "_user", $$v);
      },
      expression: "$data._user"
    }
  }, 'v-autocomplete', Object.assign({}, _vm.$attrs, {
    items: _vm.items,
    itemText: _vm.itemText,
    itemValue: '_id'
  }), false));

}
var staticRenderFns = []

export { render, staticRenderFns }