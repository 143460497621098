<template>
    <v-dialog width="100%" max-width="320" content-class="rounded-0">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on, shows }" />
        </template>
        <v-card tile>
            <v-data-table v-bind="{ items, headers }" mobile-breakpoint="10000" disable-pagination disable-sort hide-default-footer>
                <template #top> <v-card-subtitle class="mb-n4"> 클레임정보 </v-card-subtitle> </template>
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.delivery.number`]="{ item, value }">
                    <template v-if="item?.delivery?.url">
                        <v-btn text :href="`${item.delivery.url}${value}`" target="_blank" class="mr-n4"> {{ value }} </v-btn>
                    </template>
                    <template v-else> {{ value }} </template>
                </template>
            </v-data-table>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        purchase: { type: Object, default: () => {} },
    },
    data: () => ({}),
    computed: {
        shows() {
            return !!this.purchase?.claimStatus;
        },
        items() {
            return [this.purchase];
        },
        headers() {
            const headers = [];

            headers.push({ text: "사유", value: "claimReason" });
            headers.push({ text: "상세", value: "claimReasonDetails" });

            if (this.purchase?.feedbackMessage) headers.push({ text: "답변", value: "feedbackMessage" });

            headers.push({ text: "상태", value: "claimStatusMessage", cellClass: "text-bold" });

            if (this.purchase?.rejectedAt) headers.push({ text: "거부일시", value: "rejectedAt", formatter: (value) => value?.toDateTime?.() || value || "-" });

            if (this.purchase?.canceledAt) headers.push({ text: "취소일시", value: "canceledAt", formatter: (value) => value?.toDateTime?.() || value || "-" });

            if (this.purchase?.returnedAt) headers.push({ text: "반품일시", value: "returnedAt", formatter: (value) => value?.toDateTime?.() || value || "-" });

            if (this.purchase?.exchangeShippedAt) headers.push({ text: "재배송일시", value: "exchangeShippedAt", formatter: (value) => value?.toDateTime?.() || value || "-" });

            if (this.purchase?.exchangedAt) headers.push({ text: "교환일시", value: "exchangedAt", formatter: (value) => value?.toDateTime?.() || value || "-" });

            return headers.map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), cellClass: "caption", divider: index != array.length - 1 }));
        },
    },
};
</script>

<style lang="scss" scoped>
.table-label {
    position: absolute;
    left: 0;
    bottom: 100%;
    transform: translateY(50%) scale(0.75);

    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
}
::v-deep {
    .v-data-table__mobile-row {
        padding: 4px 16px !important;
        &,
        & * {
            height: auto !important;
            min-height: auto;
            font-size: 12px;
            font-weight: normal;
        }
        &:first-child {
            margin-top: 12px;
        }
        &:last-child {
            margin-bottom: 12px;
        }
    }
}
</style>
