<template>
    <v-layout justify-center>
        <v-responsive max-width="720px" width="100%">
            <div class="headline text-start mt-8">브랜드 등록</div>

            <v-row class="mt-4" justify="center">
                <v-col cols="12" algin>
                    <v-card>
                        <v-card-title class="subtitle-1">썸네일</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col v-if="image || brand.image" cols="auto" class="py-0">
                                    <v-img :src="image ? createObjectURL(image) : `/res/shop/brands/${brand._id}/${brand.image}`" max-width="128" max-height="128"></v-img>
                                </v-col>
                                <v-col v-else cols="auto" class="py-0">
                                    <v-responsive width="128" height="128">
                                        <v-row class="fill-height ma-0 grey lighten-3" align="center" justify="center">
                                            <v-icon>mdi-image</v-icon>
                                        </v-row>
                                    </v-responsive>
                                </v-col>
                                <v-col class="py-0">
                                    <v-file-input v-model="image" label="썸네일 업로드" persistent-placeholder dense hide-details show-size></v-file-input>
                                </v-col>
                            </v-row>
                            <v-row>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-card class="mt-6">
                <v-card-text>
                    <v-text-field v-model="brand.name" label="브랜드명" persistent-placeholder dense hide-details class="mt-3"></v-text-field>
                    <v-text-field v-model="brand.code" label="브랜드코드" persistent-placeholder dense hide-details class="mt-6"></v-text-field>
                    <v-text-field v-model="brand.sequence" label="순서" persistent-placeholder dense hide-details class="mt-6"></v-text-field>
                </v-card-text>
            </v-card>

            <v-card class="mt-6">
                <v-card-title>
                    <v-row>
                        <v-col class="subtitle-1 py-0">사용여부(사용/미사용)</v-col>
                        <v-col cols="auto" class="py-0">
                            <v-switch v-model="brand.display" dense hide-details class="mt-0"></v-switch>
                        </v-col>
                    </v-row>
                </v-card-title>
            </v-card>

            <v-row justify="center" class="mt-6">
                <v-col cols="auto" class="py-0">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
export default {
    data: () => {
        return {
            ///////////////////////////////////////////////
            // 상품정보
            ///////////////////////////////////////////////
            brand: {
                _id: null,
                sequence: 1,
                name: '',
                code: '',
                image: null,
                display: true,
            },
            image: null,
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                if(this.$route.params._brand){
                    var { brand } = await api.console.shop.brands.getBrand({ _id: this.$route.params._brand });
                    this.brand = brand;
                }
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },

        async save(){
            try{
                var { brand } = this.brand._id ? await api.console.shop.brands.putBrand(this.brand) : await api.console.shop.brands.postBrand(this.brand);

                if(this.image) await api.console.shop.brands.postImage(brand._id, this.image);

                alert(this.brand._id ? "수정되었습니다" : "등록되었습니다");

                this.$router.push({
                    path: "/console/shop/brands"
                });
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },

        createObjectURL(file){
            try{ return URL.createObjectURL(file); }
            catch(error){}
        }

    }
}
</script>
