<template>
    <list-search v-bind="{ showsSearch }" @search="setQuery">
        <v-row align="center">
            <v-col cols="12" sm="6" md="3" lg="">
                <v-text-field v-model="query.createdAt[0]" type="date" label="지급/사용일(시작)" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="">
                <v-text-field v-model="query.createdAt[1]" type="date" label="지급/사용일(종료)" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="">
                <v-select label="검색대상" v-model="query.searchKey" :items="searchKeys" placeholder="전체" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="">
                <v-text-field label="검색어" v-model="query.searchValue" clearable v-bind="attrs_input" @keydown.enter="setQuery" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { attrs_input } from "@/assets/variables";

import ListSearch from "@/components/console/dumb/list-search.vue";

let initQuery = (query = {}) => ({
    ...query,

    ["createdAt"]: query?.["createdAt"] || [null, null],

    ["searchKey"]: query?.["searchKey"] || null,
    ["searchValue"]: query?.["searchValue"] || null,
});

const searchKeys = [
    { text: "회원이름", value: "name" },
    { text: "휴대폰번호", value: "phone" },
];

export default {
    components: {
        ListSearch,
    },
    props: {
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),

        searchKeys,

        attrs_input,
    }),
    mounted() {
        this.sync();
    },

    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        setQuery() {
            const { ...query } = this.query || {};

            if (!query?.["createdAt"]) delete query.createdAt;
            if (!query?.["createdAt"]?.[0] && !query?.createdAt?.[1]) delete query.createdAt;

            if (!query?.["searchKey"]) delete query.searchKey;
            if (!query?.["searchValue"]) delete query.searchValue;

            query.page = 1;

            console.log(query);

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
