<template>
    <v-layout justify-center>
        <v-responsive max-width="1080" width="100%" class="px-3 mx-n3">
            <list-heading title="이용약관 관리" />

            <v-data-table :headers="headers" :items="termsList" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.terms`]="{ item }">
                    <v-responsive>
                        <v-row @click="$set(item, 'show', !item.show)">
                            <v-col class="text-start">{{ item.subject }}</v-col>
                            <v-spacer />
                            <v-col cols="auto">
                                <v-icon v-if="!item.show">mdi-chevron-down</v-icon>
                                <v-icon v-else>mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-expand-transition>
                            <v-row v-show="item.show" class="mx-0">
                                <v-textarea v-model="item.content" readonly text hide-details class="text-body-1"></v-textarea>
                            </v-row>
                        </v-expand-transition>
                    </v-responsive>
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="((editItem = Object.assign({}, item)) || true) && (dialog = true)"> mdi-pencil </v-icon>
                    <v-icon small @click="remove(item)"> mdi-delete </v-icon>
                </template>
            </v-data-table>

            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />

            <v-dialog v-model="dialog" max-width="500px">
                <template #activator="{ attrs, on }">
                    <v-btn bottom color="accent" dark fab fixed right v-bind="attrs" v-on="on" @click="editItem = Object.assign({}, defaultItem)"> <v-icon>mdi-pencil</v-icon> </v-btn>
                </template>
                <v-card>
                    <v-card-title v-if="editItem._id" class="subtitle-1">약관 수정</v-card-title>
                    <v-card-title v-else class="subtitle-1">약관 등록</v-card-title>
                    <v-card-text>
                        <v-text-field v-model="editItem.code" label="약관코드" persistent-placeholder hide-details></v-text-field>
                        <v-text-field v-model="editItem.subject" label="제목" persistent-placeholder hide-details class="mt-4"></v-text-field>
                        <v-textarea v-model="editItem.content" label="내용" persistent-placeholder hide-details class="mt-4"></v-textarea>
                        <v-row class="mt-4" justify="center" align="center">
                            <v-col cols="auto" class="py-0 subtitle-1">필수여부</v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="auto" class="py-0">
                                <v-switch v-model="editItem.necessary" hide-details class="mt-0 pa-0"></v-switch>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="dialog = false">취소</v-btn>
                        <v-btn text color="primary" v-bind="{ loading }" @click="save">저장</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

import ListHeading from "@/components/console/dumb/list-heading.vue";

const headers = [
    { text: "약관코드", value: "code", width: 140 },
    { text: "이용약관", value: "terms" },
    { text: "작성일자", value: "createdAt", width: 110, formatter: (value) => value?.toDateTime?.() },
    { text: "Actions", value: "actions", width: +80 },
];

export default {
    components: {
        ListHeading,
    },
    created() {
        this.init();
    },
    data: () => ({
        termsList: [],

        limit: 10,
        summary: { totalCount: 0 },

        headers,

        dialog: false,
        loading: false,

        editItem: {
            _id: null,
            code: null,
            subject: null,
            content: null,
            necessary: true,
        },

        defaultItem: {
            _id: null,
            code: null,
            subject: null,
            content: null,
            necessary: true,
        },
    }),

    computed: {
        items() {
            return [...this.termsList];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
    },

    methods: {
        async init() {
            try {
                await this.search();
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, termsList } = await api.console.terms.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.termsList = termsList;
            } finally {
                this.loading = false;
            }
        },

        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { post, put } = api.console.terms;
                await (this.editItem._id ? put : post)(this.editItem);
            } finally {
                this.loading = false;
                this.dialog = false;
                await this.search();
            }
        },

        async remove(term) {
            const go = confirm(`"${term?.subject}" 항목을 삭제하시겠습니까?`);
            if (!go) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                await api.console.terms.delete(term);
            } finally {
                this.loading = false;
                await this.search();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .white-space-pre-line {
        white-space: pre-line;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
}
</style>
