var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mt-3 shop-product-supplies",
    attrs: {
      "disabled": _vm.disabled
    }
  }, [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "subtitle-1 py-0"
  }, [_vm._v("특정 등급 회원노출 (사용/미사용)")]), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.input
    },
    model: {
      value: _vm.isGrade,
      callback: function ($$v) {
        _vm.isGrade = $$v;
      },
      expression: "isGrade"
    }
  })], 1)], 1)], 1), _vm.isGrade ? _c('v-card-text', [_c('v-select', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "multiple": "",
      "items": _vm.grades,
      "item-text": "name",
      "item-value": "_id",
      "placeholder": "회원 등급을 선택해 주세요.",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.value._grade,
      callback: function ($$v) {
        _vm.$set(_vm.value, "_grade", $$v);
      },
      expression: "value._grade"
    }
  })], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }