var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "disabled": !_vm.src,
      "fullscreen": "",
      "transition": "fade-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-avatar', _vm._g(_vm._b({}, 'v-avatar', Object.assign({}, attrs, _vm.avatarAttrs), false), on), [_c('v-img', {
          attrs: {
            "src": _vm.src,
            "alt": ""
          },
          scopedSlots: _vm._u([{
            key: "placeholder",
            fn: function () {
              return [_c('v-icon', {
                attrs: {
                  "dark": ""
                }
              }, [_vm._v("mdi-image")])];
            },
            proxy: true
          }], null, true)
        })], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    staticClass: "rounded-0 d-flex justify-center align-center",
    attrs: {
      "dark": "",
      "ripple": false
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.src
    }
  }), _c('v-btn', {
    staticStyle: {
      "background-color": "rgba(0,0,0,0.3)"
    },
    attrs: {
      "absolute": "",
      "right": "",
      "top": "",
      "icon": "",
      "tile": "",
      "dark": "",
      "x-large": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "4rem"
    }
  }, [_vm._v("mdi-close")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }