var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "1280",
      "persistent": "",
      "fullscreen": _vm.$vuetify.breakpoint.mobile
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', _vm._b({
    staticClass: "d-flex flex-column",
    attrs: {
      "light": "",
      "tile": _vm.$vuetify.breakpoint.mobile
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', [_vm._v(" 주문상세 "), _c('v-spacer'), _vm._v(" "), _c('v-icon', {
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "pt-3",
    staticStyle: {
      "flex": "1 0 auto",
      "overflow-y": "auto"
    }
  }, [_c('v-row', {
    staticClass: "mx-n2"
  }, [_c('v-col', {
    staticClass: "d-flex flex-column pa-2",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('order-view-baseinfo', _vm._b({}, 'order-view-baseinfo', {
    order: _vm.order
  }, false)), _c('order-view-receiver', _vm._b({
    staticClass: "mt-4"
  }, 'order-view-receiver', {
    order: _vm.order
  }, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('order-view-payment', _vm._b({}, 'order-view-payment', {
    order: _vm.order
  }, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-textarea', _vm._b({
    attrs: {
      "label": "메모",
      "color": "primary",
      "auto-grow": "",
      "rows": "9"
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('v-btn', {
          staticClass: "mr-n2 mb-n2",
          attrs: {
            "absolute": "",
            "right": "",
            "bottom": "",
            "small": "",
            "text": "",
            "color": "primary"
          },
          on: {
            "click": _vm.save
          }
        }, [_vm._v(" 저장하기 ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.order.memo,
      callback: function ($$v) {
        _vm.$set(_vm.order, "memo", $$v);
      },
      expression: "order.memo"
    }
  }, 'v-textarea', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('order-view-purchases', _vm._b({}, 'order-view-purchases', _vm.order, false))], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }