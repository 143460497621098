var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.value ? _c('v-card', {
    staticClass: "mt-6 shop-product-supplies"
  }, [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "subtitle-1 py-0"
  }, [_vm._v("배송설정 (사용/미사용)")]), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.input
    },
    model: {
      value: _vm.shippingEnabled,
      callback: function ($$v) {
        _vm.shippingEnabled = $$v;
      },
      expression: "shippingEnabled"
    }
  })], 1)], 1)], 1), _vm.shippingEnabled ? _c('v-card-text', [_c('v-radio-group', {
    staticClass: "mt-0",
    attrs: {
      "dense": ""
    },
    on: {
      "change": function ($event) {
        return _vm.input();
      }
    },
    model: {
      value: _vm.$data._shipping,
      callback: function ($$v) {
        _vm.$set(_vm.$data, "_shipping", $$v);
      },
      expression: "$data._shipping"
    }
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.shippingsHeaders,
      "items": _vm.shippings,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.shipping",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-radio', {
          attrs: {
            "label": item.name,
            "value": item._id
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_c('v-icon', {
                staticClass: "mx-2",
                attrs: {
                  "small": "",
                  "color": item.color
                }
              }, [_vm._v(_vm._s(item.icon))]), _c('span', {
                staticClass: "subtitle-2"
              }, [_vm._v(_vm._s(item.name))])];
            },
            proxy: true
          }], null, true)
        })];
      }
    }, {
      key: "item.config",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [item.code == 'product' ? _c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-text-field', {
          attrs: {
            "label": "배송비(원)",
            "persistent-placeholder": "",
            "outlined": "",
            "dense": "",
            "hide-details": ""
          },
          on: {
            "input": function ($event) {
              item.price = item.price.replace(/[^0-9]/, '');
              _vm.input();
            }
          },
          model: {
            value: item.price,
            callback: function ($$v) {
              _vm.$set(item, "price", $$v);
            },
            expression: "item.price"
          }
        })], 1)], 1) : _vm._e(), item.code == 'order' ? _c('v-row', [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v(" " + _vm._s(item.range.basePrice.format()) + " ")]), _c('v-col', {
          staticClass: "py-0"
        }, [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v("이상 구매시")]), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v(" " + _vm._s(item.range.highPrice.format()) + " ")])], 1), _c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v("미만 구매시")]), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v(" " + _vm._s(item.range.lowPrice.format()) + " ")])], 1)], 1)], 1) : _vm._e(), item.code == 'free' ? _c('v-row', [_c('v-col', {
          staticClass: "py-0 text-start"
        }, [_vm._v("※ 해당 제품 구매시 무료배송으로 표시됩니다.")])], 1) : _vm._e(), item.code == 'later' ? _c('v-row', [_c('v-col', {
          staticClass: "py-0 text-start"
        }, [_vm._v("※ 해당 제품 구매시 착불배송으로 표시됩니다.")])], 1) : _vm._e()];
      }
    }], null, false, 2834351817)
  })], 1)], 1) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }