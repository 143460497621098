var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-autocomplete', {
    attrs: {
      "label": _vm.label,
      "placeholder": _vm.placeholder,
      "dense": _vm.dense,
      "hide-details": _vm.hideDetails,
      "loading": _vm.loading,
      "search-input": _vm.searchValue,
      "items": _vm.products,
      "item-text": "name",
      "return-object": ""
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.searchValue = $event;
      },
      "update:search-input": function ($event) {
        _vm.searchValue = $event;
      }
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }