var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "640px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_vm.isCreate ? _c('v-btn', _vm._g({
          attrs: {
            "bottom": "",
            "color": "accent",
            "dark": "",
            "fab": "",
            "fixed": "",
            "right": ""
          }
        }, on), [_c('v-icon', [_vm._v("mdi-pencil")])], 1) : _c('v-icon', _vm._g({
          attrs: {
            "small": ""
          }
        }, on), [_vm._v(" mdi-pencil ")])];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_vm.dialog ? _c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-1 font-weight-bold"
  }, [_vm._v(" 띠배너 " + _vm._s(_vm.item._id ? "수정" : "생성") + " ")])]), _c('v-divider'), _c('v-card-text', {
    staticClass: "pa-6"
  }, [_c('v-row', [_c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "label": "코드"
    },
    model: {
      value: _vm.item.code,
      callback: function ($$v) {
        _vm.$set(_vm.item, "code", $$v);
      },
      expression: "item.code"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "label": "명칭"
    },
    model: {
      value: _vm.item.name,
      callback: function ($$v) {
        _vm.$set(_vm.item, "name", $$v);
      },
      expression: "item.name"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "label": "URL"
    },
    model: {
      value: _vm.item.href,
      callback: function ($$v) {
        _vm.$set(_vm.item, "href", $$v);
      },
      expression: "item.href"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1)], 1), _c('v-row', {
    staticClass: "px-1"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', _vm._b({
    attrs: {
      "label": "노출",
      "color": "secondary"
    },
    model: {
      value: _vm.item.display.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.item.display, "enabled", $$v);
      },
      expression: "item.display.enabled"
    }
  }, 'v-switch', _vm.switchAttrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', _vm._b({
    attrs: {
      "color": "secondary",
      "label": "기간노출"
    },
    model: {
      value: _vm.item.display.hasPeriod,
      callback: function ($$v) {
        _vm.$set(_vm.item.display, "hasPeriod", $$v);
      },
      expression: "item.display.hasPeriod"
    }
  }, 'v-switch', _vm.switchAttrs, false))], 1)], 1), _c('v-expand-transition', {
    model: {
      value: _vm.item.display.hasPeriod,
      callback: function ($$v) {
        _vm.$set(_vm.item.display, "hasPeriod", $$v);
      },
      expression: "item.display.hasPeriod"
    }
  }, [_c('v-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.item.display.hasPeriod,
      expression: "item.display.hasPeriod"
    }]
  }, [_c('v-col', [_c('v-datetime-field', _vm._b({
    attrs: {
      "label": "시작일",
      "padding": "0"
    },
    model: {
      value: _vm.item.display.startedAt,
      callback: function ($$v) {
        _vm.$set(_vm.item.display, "startedAt", $$v);
      },
      expression: "item.display.startedAt"
    }
  }, 'v-datetime-field', _vm.inputAttrs, false))], 1), _c('v-col', [_c('v-datetime-field', _vm._b({
    attrs: {
      "label": "종료일",
      "padding": "0"
    },
    model: {
      value: _vm.item.display.endedAt,
      callback: function ($$v) {
        _vm.$set(_vm.item.display, "endedAt", $$v);
      },
      expression: "item.display.endedAt"
    }
  }, 'v-datetime-field', _vm.inputAttrs, false))], 1)], 1)], 1), _c('v-row', {
    staticClass: "mx-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('span', {
    staticClass: "caption"
  }, [_vm._v("이미지(PC)")]), _c('image-input', {
    attrs: {
      "outlined": "",
      "flat": "",
      "width": "100%",
      "aspect-ratio": 1 / 1
    },
    model: {
      value: _vm.item.imagePc,
      callback: function ($$v) {
        _vm.$set(_vm.item, "imagePc", $$v);
      },
      expression: "item.imagePc"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('span', {
    staticClass: "caption"
  }, [_vm._v("이미지(모바일)")]), _c('image-input', {
    attrs: {
      "outlined": "",
      "flat": "",
      "width": "100%",
      "aspect-ratio": 1 / 1
    },
    model: {
      value: _vm.item.imageMb,
      callback: function ($$v) {
        _vm.$set(_vm.item, "imageMb", $$v);
      },
      expression: "item.imageMb"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }