<template>
    <v-data-table v-bind="{ items, headers, itemClass }" :items-per-page="-1" disable-pagination disable-sort hide-default-footer class="v-sheet--outlined">
        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
        <template #[`item.product`]="{ item }">
            <v-row class="pa-3 pa-sm-0" align="center">
                <v-col cols="12" sm="auto" class="pa-0 pa-sm-3 purchase-item-image">
                    <image-popup :src="`/res/shop/products/${item?.product?._id}/${item?.product?.thumb}`" size="40" rounded="lg" />
                </v-col>
                <v-col class="pa-0 pa-sm-3">
                    <div class="caption">{{ item.purchaseNo }}</div>
                    <div>
                        {{ item?.product?.name }}
                        <br class="d-sm-none" />
                        <span class="d-none d-sm-inline">/</span>
                        {{ item?.name }}
                    </div>
                </v-col>
            </v-row>
        </template>
        <template #[`item.orderStatusMessage`]="{ item, value }">
            <v-layout align-center>
                <span>{{ value }}</span>
                <order-view-delivery :purchase="item">
                    <template #activator="{ attrs, on, shows }">
                        <v-btn v-if="shows" icon text small v-bind="attrs" v-on="on"> <v-icon small>mdi-truck-outline</v-icon> </v-btn>
                    </template>
                </order-view-delivery>
            </v-layout>
        </template>
        <template #[`item.claimStatusMessage`]="{ item, value }">
            <v-layout align-center>
                <span>{{ value }}</span>
                <order-view-claim :purchase="item">
                    <template #activator="{ attrs, on, shows }">
                        <v-btn v-if="shows" icon text small v-bind="attrs" v-on="on"> <v-icon small>mdi-clipboard-alert-outline</v-icon> </v-btn>
                    </template>
                </order-view-claim>
            </v-layout>
        </template>
    </v-data-table>
</template>

<script>
import { PURCHASE_ORDER_STATUSES } from "@/assets/variables";

import ImagePopup from "@/components/console/dumb/image-popup.vue";
import OrderViewDelivery from "./order-view-delivery.vue";
import OrderViewClaim from "./order-view-claim.vue";

const headers = [
    { width: 440, text: "상품", value: "product" },
    { width: 100, text: "판매가", value: "salePrice", formatter: (value) => value?.format?.() || value || "-", align: "center" },
    { width: +70, text: "수량", value: "amount", formatter: (value) => value?.format?.() || value || "-", align: "center" },
    { width: 100, text: "합계", value: "totalPrice", formatter: (value) => value?.format?.() || value || "-", align: "center" },
    { width: 140, text: "주문상태", value: "orderStatusMessage" },
    { width: 140, text: "클레임상태", value: "claimStatusMessage" },
    { width: 120, text: "수거상태", value: "collectionStatusMessage" },
    { width: 120, text: "구매확정상태", value: "purchaseStatusMessage" },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), cellClass: "caption", divider: index != array.length - 1 }));

export default {
    components: {
        ImagePopup,
        OrderViewDelivery,
        OrderViewClaim,
    },
    props: {
        purchases: { type: Array, default: () => [] },
    },
    data: () => ({
        headers,
    }),
    computed: {
        items() {
            return [...this.purchases].map((item) => ({ ...item, totalPrice: item.salePrice * item.amount }));
        },
    },
    methods: {
        itemClass(item) {
            let className = "";
            if (PURCHASE_ORDER_STATUSES.ORDER_CANCELED.value == item?.orderStatus) {
                className += " item--canceled";
            }
            return className.trim();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-data-table__mobile-row {
        padding: 4px 16px !important;
        &,
        & > * {
            height: auto !important;
            max-height: auto !important;
            min-height: auto !important;
            font-size: 12px;
            font-weight: normal;
        }

        &:last-child {
            padding-bottom: 16px !important;
        }
    }
}
</style>

<style lang="scss" scoped>
::v-deep {
    .item--canceled {
        color: rgba(0, 0, 0, 0.5);

        .purchase-item-image {
            opacity: 0.5;
        }
    }
}
</style>
