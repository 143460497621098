var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    staticClass: "pr-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('image-popup', _vm._b({}, 'image-popup', {
    src: _vm.src,
    size: _vm.size
  }, false))], 1), _c('v-col', [_c('v-file-input', _vm._b({
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.file,
      callback: function ($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  }, 'v-file-input', Object.assign({}, _vm.$attrs, {
    accept: _vm.accept
  }), false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }