<template>
    <v-dialog width="100%" max-width="320" content-class="rounded-0">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on, shows }" />
        </template>
        <v-card tile>
            <v-data-table v-bind="{ items, headers }" mobile-breakpoint="10000" disable-pagination disable-sort hide-default-footer>
                <template #top> <v-card-subtitle class="mb-n4"> 입금정보 </v-card-subtitle> </template>
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
            </v-data-table>
        </v-card>
    </v-dialog>
</template>

<script>
import api from '@/api';
import banks from "@/plugins/shop-default-banks.json";

export default {
    props: {
        order: { type: Object, default: () => {} },
    },
    data: () => ({
        setting: undefined,
    }),
    computed: {
        shows() {
            const { name, number, method } = this.order?.delivery || {};
            return [name, number, method].some((item) => item);
        },
        items() {
            return [this.order];
        },
        headers() {
            const headers = [];

            headers.push({ text: "은행명", value: "account.code", formatter: (value) => banks.find((bank) => bank.code == (value || this.setting?.shop?.accountBankCode))?.name });
            headers.push({ text: "예금주명", value: "account.accountHolder", formatter: (value) => value || this.setting?.shop?.accountHolder });
            headers.push({ text: "계좌번호", value: "account.accountNumber", formatter: (value) => value || this.setting?.shop?.accountNumber });
            headers.push({ text: "입금자명", value: "account.senderName", formatter: (value) => value || this.order?.sender?.name });
            headers.push({ text: "입금금액", value: "totalPrice", formatter: (value) => (value?.format?.() || value || 0) + "원" });

            return headers.map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), cellClass: "caption", divider: index != array.length - 1 }));
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.setting = (await api.v1.setting.get())?.setting;
        },
    },
};
</script>

<style lang="scss" scoped>
.table-label {
    position: absolute;
    left: 0;
    bottom: 100%;
    transform: translateY(50%) scale(0.75);

    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
}
::v-deep {
    .v-data-table__mobile-row {
        padding: 4px 16px !important;
        &,
        & * {
            height: auto !important;
            min-height: auto;
            font-size: 12px;
            font-weight: normal;
        }
        &:first-child {
            margin-top: 12px;
        }
        &:last-child {
            margin-bottom: 12px;
        }
    }
}
</style>
