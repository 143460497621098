<template>
    <v-layout justify-center>
        <v-responsive max-width="880" width="100%" height="100%" class="px-3 mx-n3">
            <list-heading title="직급 목록">
                <!-- <template #add-button>
                    <user-grade-form @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn small fab tile color="transparent primary--text" class="my-n1" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </user-grade-form>
                </template> -->
            </list-heading>

            <v-data-table v-bind="{ loading, headers, items }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined" :items-per-page="-1">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.criteria`]="{ item }">
                    <user-grade-criteria v-model="item.criteria" />
                </template>
                <template #[`item.benefits`]="{ item }">
                    <user-grade-benefits v-model="item.benefits" />
                </template>
                <template #[`item.isActive`]="{ item }">
                    <v-switch v-model="item.isActive" v-bind="attrs_switch" disabled color="grey darken-1" />
                    <!-- <v-switch v-model="item.isActive" v-bind="attrs_switch" @change="(isActive) => update({ _id: item._id, isActive })" /> -->
                </template>
                <template #[`item.actions`]="{ item }">
                    <!-- <user-grade-form v-bind="item" @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn small text icon tile v-bind="attrs" v-on="on">
                                <v-icon small> mdi-pencil </v-icon>
                            </v-btn>
                        </template>
                    </user-grade-form> -->
                    <v-btn small text icon tile @click="remove(item)">
                        <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_switch } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import UserGradeCriteria from "@/components/console/users/grades/user-grade-criteria.vue";
import UserGradeBenefits from "@/components/console/users/grades/user-grade-benefits.vue";
// import UserGradeForm from "@/components/console/user-grades/user-grdae-form.vue";

const headers = [
    { divider: true, width: +60, text: "Lv.", value: "value", align: "center" },
    { divider: true, width: 100, text: "호칭", value: "title", align: "center" },
    { divider: true, width: 380, text: "기준", value: "criteria", align: "center", cellClass: "px-0 vertical-align-top" },
    { divider: true, width: 220, text: "혜택", value: "benefits", align: "center", cellClass: "px-0 vertical-align-top" },
    { width: +80, text: "활성화", value: "isActive", align: "center" },
    // { width: +88, text: "", value: "actions", align: "center" },
].map((item) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-") }));

export default {
    components: {
        ListHeading,
        UserGradeCriteria,
        UserGradeBenefits,
        // UserGradeForm,
    },
    data() {
        return {
            grades: [],

            limit: 20,
            summary: { totalCount: 0 },

            headers,
            attrs_switch,
            loading: false,
        };
    },
    computed: {
        items() {
            return this.grades.map((item, index) => ({ ...item, index: index + 1 }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { query } = this.$route;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },
        async search() {
            this.loading = true;
            try {
                let { skip, limit, params } = this;
                var { summary, grades } = await api.console.users.grades.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.grades = grades.map((item, index) => ({ index: summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        async update(code) {
            try {
                code = (await api.console.users.grades.put(code))?.code;
                this.updateItem(code);
            } catch (error) {
                this.$handleError(error);
            }
        },

        async remove(code) {
            const go = confirm(`"${code?.name}" 상품을 삭제하시겠습니까?`);
            if (go) {
                try {
                    [{ code }] = [await api.console.users.grades.delete(code)];
                    this.grades = this.grades.filter(({ _id }) => _id !== code._id);
                    alert(`"${code?.name}" 상품이 삭제되었습니다.`);
                    this.summary.totalCount -= 1;
                } catch (error) {
                    this.$handleError(error);
                }
            }
        },

        updateItem(code) {
            const index = this.grades.findIndex(({ _id }) => _id == code._id);
            if (0 <= index) this.grades.splice(index, 1, code);
            else {
                this.grades = [code, ...this.grades];
                this.summary.totalCount += 1;
            }
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .vertical-align-top {
        vertical-align: top;
    }
}
</style>
