<template>
    <v-dialog width="100%" max-width="320" content-class="rounded-0">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on, shows }" />
        </template>
        <v-card tile>
            <v-data-table v-bind="{ items, headers }" mobile-breakpoint="10000" disable-pagination disable-sort hide-default-footer>
                <template #top> <v-card-subtitle class="mb-n4"> 배송정보 </v-card-subtitle> </template>
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.delivery.number`]="{ item, value }">
                    <template v-if="item?.delivery?.url">
                        <v-btn text :href="`${item.delivery.url}${value}`" target="_blank" class="mr-n4"> {{ value }} </v-btn>
                    </template>
                    <template v-else> {{ value }} </template>
                </template>
            </v-data-table>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        purchase: { type: Object, default: () => {} },
    },
    computed: {
        shows() {
            const { name, number, method } = this.purchase?.delivery || {};
            return [name, number, method].some((item) => item);
        },
        items() {
            return [this.purchase];
        },
        headers() {
            const headers = [];

            headers.push({ text: "배송방법", value: "delivery.method" });
            headers.push({ text: "택배업체", value: "delivery.name" });
            headers.push({ text: "송장번호", value: "delivery.number" });

            if (this.purchase?.shippedAt) headers.push({ text: "배송시작일", value: "shippedAt", formatter: (value) => value?.toDateTime?.() || value || "-" });

            if (this.purchase?.deliveredAt) headers.push({ text: "배송완료일", value: "deliveredAt", formatter: (value) => value?.toDateTime?.() || value || "-" });

            return headers.map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), cellClass: "caption", divider: index != array.length - 1 }));
        },
    },
};
</script>

<style lang="scss" scoped>
.table-label {
    position: absolute;
    left: 0;
    bottom: 100%;
    transform: translateY(50%) scale(0.75);

    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
}
::v-deep {
    .v-data-table__mobile-row {
        padding: 4px 16px !important;
        &,
        & * {
            height: auto !important;
            min-height: auto;
            font-size: 12px;
            font-weight: normal;
        }
        &:first-child {
            margin-top: 12px;
        }
        &:last-child {
            margin-bottom: 12px;
        }
    }
}
</style>
