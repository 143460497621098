var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "d-flex flex-column fill-height",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-card-subtitle', {
    staticClass: "d-flex justify-space-between align-center font-weight-bold",
    staticStyle: {
      "flex": "0 0 auto"
    }
  }, [_vm._v(" 구매확정내역 (추천회원) ")]), _c('v-divider'), _c('v-data-table', _vm._b({
    staticStyle: {
      "flex": "1 0 auto"
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.product`,
      fn: function (_ref2) {
        var _item$product;
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$product = item.product) === null || _item$product === void 0 ? void 0 : _item$product.name) + " - " + _vm._s(item === null || item === void 0 ? void 0 : item.name) + " ")];
      }
    }, {
      key: "footer",
      fn: function () {
        var _vm$purchasesPrice__t, _vm$purchasesPrice__t2;
        return [_c('v-divider'), _vm._v(" "), _c('v-subheader', {
          staticClass: "justify-center"
        }, [_vm._v(" 구매확정금액 합계: " + _vm._s(((_vm$purchasesPrice__t = _vm.purchasesPrice__total) === null || _vm$purchasesPrice__t === void 0 ? void 0 : (_vm$purchasesPrice__t2 = _vm$purchasesPrice__t.format) === null || _vm$purchasesPrice__t2 === void 0 ? void 0 : _vm$purchasesPrice__t2.call(_vm$purchasesPrice__t)) || 0) + "원 ")])];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }