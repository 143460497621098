<template>
<v-container fluid fill-height>
    <v-layout justify-center>
        <v-responsive max-width="1280px" width="100%">

            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">상품목록</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showsSearch = !showsSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showsSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-card-text class="pt-4 pb-0">
                        <v-row>
                            <v-col cols="12" class="py-0">
                                <shop-category-select v-model="category" dense hide-details :categories="categories" @input="filter._category = category._id"></shop-category-select>
                            </v-col>
                        </v-row>
                        <v-row class="mt-6">
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field v-model="filter.name" dense hide-details type="text" label="상품명" persistent-placeholder />
                            </v-col>
                        </v-row>
                        <v-row class="mt-6">
                            <v-col cols="12" sm="3" class="py-sm-0">
                                <v-select v-model="filter.newly" :items="newlyItems" item-text="text" item-value="value" dense hide-details type="text" label="신상여부" persistent-placeholder />
                            </v-col>
                            <v-col cols="12" sm="3" class="py-sm-0">
                                <v-select v-model="filter.best" :items="bestItems" item-text="text" item-value="value" dense hide-details type="text" label="베스트" persistent-placeholder />
                            </v-col>
                            <v-col cols="12" sm="3" class="py-sm-0">
                                <v-select v-model="filter.special" :items="specialItems" item-text="text" item-value="value" dense hide-details type="text" label="특가여부" persistent-placeholder />
                            </v-col>
                        </v-row>
                        <v-row class="mt-6">
                            <v-col cols="12" sm="3" class="py-sm-0">
                                <v-select v-model="filter.display" :items="displayItems" item-text="text" item-value="value" dense hide-details type="text" label="진열여부" persistent-placeholder />
                            </v-col>
                            <v-col cols="12" sm="3" class="py-sm-0">
                                <v-select v-model="filter.soldout" :items="soldoutItems" item-text="text" item-value="value" dense hide-details type="text" label="품절상태여부" persistent-placeholder />
                            </v-col>
                            <v-col cols="12" sm="3" class="py-sm-0">
                                <v-select v-model="filter.sale" :items="saleItems" item-text="text" item-value="value" dense hide-details type="text" label="판매여부" persistent-placeholder />
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="page = 1; search();"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-data-table :headers="productsHeaders" :items="products" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                <template v-slot:item.goods="{item}">
                    <v-row align="center">
                        <v-col md="auto">
                            <v-img v-if="item.thumb" :src="`/res/shop/products/${item._id}/${item.thumb}`" max-width="56" max-height="56"></v-img>
                            <v-responsive v-else width="56" height="56">
                                <v-row class="fill-height ma-0 grey lighten-3" align="center" justify="center">
                                    <v-icon>mdi-image</v-icon>
                                </v-row>
                            </v-responsive>
                        </v-col>
                        <v-col>
                            <div class="text-start">{{ item.name }}</div>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.price="{item}">
                    <span>{{ item.price.format() }}원</span>
                </template>
                <template v-slot:item.cost="{item}">
                    <span>{{ item.cost.format() }}원</span>
                </template>
                <template v-slot:item.stock="{item}">
                    <span>{{ item.stock.format() }}개</span>
                </template>
                <template v-slot:item.display="{item}">
                    <v-row justify="center">
                        <v-col cols="auto" class="pa-0">
                            <v-switch v-model="item.display" hide-details class="ma-0" @change="save({ _id: item._id, display: item.display })"></v-switch>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.sale="{item}">
                    <v-row justify="center">
                        <v-col cols="auto" class="pa-0">
                            <v-switch v-model="item.sale" hide-details class="ma-0" @change="save({ _id: item._id, sale: item.sale })"></v-switch>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.best="{item}">
                    <v-row justify="center">
                        <v-col cols="auto" class="pa-0">
                            <v-switch v-model="item.best" hide-details class="ma-0" @change="save({ _id: item._id, best: item.best })"></v-switch>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.newly="{item}">
                    <v-row justify="center">
                        <v-col cols="auto" class="pa-0">
                            <v-switch v-model="item.newly" hide-details class="ma-0" @change="save({ _id: item._id, newly: item.newly })"></v-switch>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.special="{item}">
                    <v-row justify="center">
                        <v-col cols="auto" class="pa-0">
                            <v-switch v-model="item.special" hide-details class="ma-0" @change="save({ _id: item._id, special: item.special })"></v-switch>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.soldout="{item}">
                    <v-row justify="center">
                        <v-col cols="auto" class="pa-0">
                            <v-switch v-model="item.soldout" hide-details class="ma-0" @change="save({ _id: item._id, soldout: item.soldout })"></v-switch>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.actions="{item}">
                    <v-icon small @click="edit(item)" title="수정">mdi-pencil</v-icon>
                    <v-icon small @click="copy(item)" title="복사" class="ml-2">mdi-content-copy</v-icon>
                    <v-icon small @click="remove(item)" title="삭제" class="ml-2">mdi-delete</v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search"></v-pagination>

            <v-btn bottom color="accent" fab fixed right @click="create">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>

        </v-responsive>
    </v-layout>
</v-container>
</template>

<script>
import api from "@/api";
import ShopCategorySelect from '@/components/console/shop/categories/shop-category-select.vue';

export default {
    components: {
        ShopCategorySelect
    },
    mounted(){
        this.init();
    },
    data(){
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showsSearch: true,

            category: null,
            filter: {
                _category: this.$route.query._category || null,
                name: this.$route.query.name || null,
                newly: JSON.parse(this.$route.query.newly || 'null'),
                best: JSON.parse(this.$route.query.best || 'null'),
                display: JSON.parse(this.$route.query.display || 'null'),
                special: JSON.parse(this.$route.query.special || 'null'),
                soldout: JSON.parse(this.$route.query.soldout || 'null'),
                sale: JSON.parse(this.$route.query.sale || 'null')
            },

            categories: [],

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            products: [],
            productsHeaders: [{
                text: '진열순서',
                align: 'center',
                width: 100,
                value: 'sequence'
            },
            {
                text: '상품',
                align: 'center',
                width: 250,
                value: 'goods'
            },
            {
                text: '판매가',
                align: 'center',
                width: 140,
                value: 'price'
            },
            {
                text: '재고',
                width: 100,
                align: 'center',
                value: 'stock'
            },
            {
                text: '신상품',
                align: 'center',
                width: 80,
                value: 'newly'
            },
            {
                text: '베스트',
                align: 'center',
                width: 80,
                value: 'best'
            },
            {
                text: '특가',
                align: 'center',
                width: 80,
                value: 'special'
            },
            {
                text: '품절여부',
                align: 'center',
                width: 80,
                value: 'soldout'
            },
            {
                text: 'Actions',
                width: 100,
                align: 'center',
                value: 'actions'
            }],

            newlyItems: [
                { text: "전체", value: null },
                { text: "신상품", value: true },
                { text: "일반", value: false }
            ],

            bestItems: [
                { text: "전체", value: null },
                { text: "적용중", value: true },
                { text: "미적용 ", value: false }
            ],

            specialItems: [
                { text: "전체", value: null },
                { text: "특가중", value: true },
                { text: "비특가 ", value: false }
            ],

            displayItems: [
                { text: "전체", value: null },
                { text: "전시중", value: true },
                { text: "미전시 ", value: false }
            ],

            soldoutItems: [
                { text: "전체", value: null },
                { text: "품절표시", value: true },
                { text: "미품절 ", value: false }
            ],

            saleItems: [
                { text: "전체", value: null },
                { text: "판매중", value: true },
                { text: "판매불가 ", value: false }
            ]
        };
    },
    methods: {
        async init(){
            try{
                var { categories } = await api.console.shop.categories.getCategories();
                this.categories = categories;

                if(this.filter._category){
                    var { category } = await api.console.shop.categories.getCategory({ _id: this.filter._category });
                    this.category = category;
                }

                this.search();
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async search(routable = true){
            try{
                if(routable){
                    this.$router.push({
                        query: Object.assignDefined({}, this.filter, { page: this.page })
                    });
                }

                var { summary, products } = await api.console.shop.products.getProducts({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit
                    },
                    params: this.filter
                });

                this.products = products;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        create(){
            this.$router.push(`/console/shop/products/create`);
        },
        copy(product){
            this.$router.push(`/console/shop/products/${product._id}?method=copy`);
        },
        edit(product){
            this.$router.push(`/console/shop/products/${product._id}`);
        },

        async save(product){
            try{
                await api.console.shop.products.putProduct(product);
                await this.search();
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        async remove(product){
            try{
                if(confirm("해당 상품을 삭제하시겠습니까?")){
                    await api.console.shop.products.deleteProduct(product);
                    await this.search();

                    alert("삭제되었습니다");
                }
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    }
}
</script>
