<template>
    <component :is="display" />
</template>

<script>
import Dashboard from "@/pages/console/Dashboard.vue";

export default {
    components: {
        Dashboard,
    },
    methods: {
        scopeIncludes(criterion) {
            return this.payload?.scope?.includes?.(criterion);
        },
    },
    computed: {
        payload() {
            return this.$store.state.payload || {};
        },
        scope() {
            return this.payload.scope || [];
        },
        display() {
            if (this.scopeIncludes("console")) return "dashboard";
        },
    },
};
</script>
