<template>
    <v-autocomplete v-model="selected" :label="label" :placeholder="placeholder" :dense="dense" :hide-details="hideDetails" :loading="loading" :search-input.sync="searchValue" :items="products" item-text="name" return-object></v-autocomplete>
</template>
<script>
import api from "@/api";

export default {
    props: {
        value: { type: Object, default: null },
        label: { type: String, default: "상품검색" },
        placeholder: { type: String, default: " " },
        dense: { type: Boolean, default: false },
        hideDetails: { type: Boolean, default: false },
    },
    data(){
        return {
            selected: this.$props.value,
            loading: false,
            products: [],
            searchValue: null
        };
    },
    methods: {
        input(){
            this.$emit("input", this.selected);
            this.$emit("change", this.selected);
        }
    },
    watch: {
        value(){
            this.selected = this.value;
        },
        selected(){
            this.input();
        },
        async searchValue(){
            if(this.loading && this.products.length > 0) return;
            this.loading = true;

            api.console.shop.products.getProducts({
                params: { searchKey: "name", searchValue: this.searchValue }
            }).then(({ products }) => {
                this.products = products;
            }).finally(() => {
                this.loading = false;
            })
        }
    }
}
</script>