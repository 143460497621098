<template>
    <v-card class="mt-3 shop-product-supplies" :disabled="disabled">
        <v-card-title>
            <v-row>
                <v-col class="subtitle-1 py-0">특정 등급 회원노출 (사용/미사용)</v-col>
                <v-col md="auto" sm="auto" class="py-0">
                    <v-switch v-model="isGrade" dense hide-details class="mt-0" @change="input"></v-switch>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text v-if="isGrade">
            <v-select v-model="value._grade" multiple :items="grades" item-text="name" item-value="_id" placeholder="회원 등급을 선택해 주세요." dense hide-details class="pa-0 ma-0" @input="input" />
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        disabled: { type: Boolean, default: false },
        value: { type: Object, default: null },
        grades: { type: Array }
    },
    data: () => {
        return {
            _grade: [],
            grade: null,
            isGrade: false
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                this._grade = this.value._grade;
                this.isGrade = this.value.isGrade;
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },
        input(){
            const updatedValue = {
                ...this.value,
                _grade: this.value._grade,
                isGrade: this.isGrade
            };

            this.$emit('input', updatedValue);
        },
    },
    watch: {
        value(){
            this.init();
        }
    }
}
</script>
