var render = function render(){
  var _vm$category, _vm$category2, _vm$category3, _vm$category4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "color": "info lighten-1",
      "dense": ""
    }
  }, [_c('v-toolbar-title', {
    staticClass: "white--text subtitle-2"
  }, [_vm._v("카테고리")]), _c('v-spacer'), _c('v-dialog', {
    attrs: {
      "width": "560"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-fab-transition', [_c('v-btn', _vm._g({
          attrs: {
            "color": "accent",
            "fab": "",
            "dark": "",
            "small": "",
            "absolute": "",
            "bottom": "",
            "right": ""
          }
        }, on), [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_vm.dialog ? _c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1",
    attrs: {
      "primary-title": ""
    }
  }, [_vm._v("신규 카테고리 등록")]), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "카테고리 이름",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.name,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "name", $$v);
      },
      expression: "editItem.name"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "카테고리 코드",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.code,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "code", $$v);
      },
      expression: "editItem.code"
    }
  })], 1)], 1), _c('v-image-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "카테고리 썸네일",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "thumb", $$v);
      },
      expression: "editItem.thumb"
    }
  }), _c('v-row', {
    staticClass: "mt-3",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v("특정 등급 회원노출")]), _vm._v(" "), _c('span', [_vm._v("(사용/미사용)")])]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.isGrade,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "isGrade", $$v);
      },
      expression: "editItem.isGrade"
    }
  })], 1)], 1), !!_vm.editItem.isGrade ? _c('v-row', {
    staticClass: "mt-3",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "items": _vm.grades,
      "item-value": "_id",
      "placeholder": "회원 등급을 선택해 주세요.",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.title) + " ( Lv." + _vm._s(item.value) + ") ")];
      }
    }, {
      key: "item",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.title) + " ( Lv." + _vm._s(item.value) + ") ")];
      }
    }], null, false, 643275004),
    model: {
      value: _vm.editItem._grade,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "_grade", $$v);
      },
      expression: "editItem._grade"
    }
  })], 1)], 1) : _vm._e(), _c('v-row', {
    staticClass: "mt-3",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v("검색엔진최적화(SEO)")]), _vm._v(" "), _c('span', [_vm._v("(사용/미사용)")])]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.seoEnabled,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "seoEnabled", $$v);
      },
      expression: "editItem.seoEnabled"
    }
  })], 1)], 1), _vm.editItem.seoEnabled ? _c('v-row', [_c('v-col', {
    staticClass: "py-3 py-md-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "타이틀: Title",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.editItem.seo.title,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.seo, "title", $$v);
      },
      expression: "editItem.seo.title"
    }
  })], 1), _c('v-col', {
    staticClass: "py-3 py-md-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "메타태그: Author",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.editItem.seo.meta.find(function (meta) {
        return meta.name == 'author';
      }).content,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.seo.meta.find(function (meta) {
          return meta.name == 'author';
        }), "content", $$v);
      },
      expression: "editItem.seo.meta.find((meta) => meta.name == 'author').content"
    }
  })], 1), _c('v-col', {
    staticClass: "py-3 py-md-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    staticClass: "mt-6",
    attrs: {
      "label": "메타태그: Description",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.editItem.seo.meta.find(function (meta) {
        return meta.name == 'description';
      }).content,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.seo.meta.find(function (meta) {
          return meta.name == 'description';
        }), "content", $$v);
      },
      expression: "editItem.seo.meta.find((meta) => meta.name == 'description').content"
    }
  })], 1), _c('v-col', {
    staticClass: "py-3 py-md-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    staticClass: "mt-6",
    attrs: {
      "label": "메타태그: Keywords",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.editItem.seo.meta.find(function (meta) {
        return meta.name == 'keywords';
      }).content,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.seo.meta.find(function (meta) {
          return meta.name == 'keywords';
        }), "content", $$v);
      },
      expression: "editItem.seo.meta.find((meta) => meta.name == 'keywords').content"
    }
  })], 1)], 1) : _vm._e()], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("등록")])], 1)], 1) : _vm._e()], 1)], 1), _c('v-card-text', [_vm.categories.length ? _c('v-draggable-treeview', {
    attrs: {
      "item-key": "_id",
      "children": "children",
      "group": "categories",
      "dense": ""
    },
    on: {
      "click": _vm.select
    },
    model: {
      value: _vm.categories,
      callback: function ($$v) {
        _vm.categories = $$v;
      },
      expression: "categories"
    }
  }) : _c('span', [_vm._v("등록된 카테고리가 없습니다."), _c('br'), _vm._v("카테고리를 등록해주세요")])], 1)], 1), _c('v-row', [_c('v-spacer'), _c('v-col', {
    attrs: {
      "md": "auto"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "disabled": !((_vm$category = _vm.category) !== null && _vm$category !== void 0 && _vm$category._id)
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("카테고리 기본정보")]), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "카테고리 코드(영문)",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.category.code,
      callback: function ($$v) {
        _vm.$set(_vm.category, "code", $$v);
      },
      expression: "category.code"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "카테고리 이름",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.category.name,
      callback: function ($$v) {
        _vm.$set(_vm.category, "name", $$v);
      },
      expression: "category.name"
    }
  })], 1)], 1), _c('v-image-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "카테고리 썸네일",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.category.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.category, "thumb", $$v);
      },
      expression: "category.thumb"
    }
  })], 1)], 1), _c('shop-category-grade', {
    attrs: {
      "disabled": !((_vm$category2 = _vm.category) !== null && _vm$category2 !== void 0 && _vm$category2._id),
      "grades": _vm.grades
    },
    model: {
      value: _vm.category,
      callback: function ($$v) {
        _vm.category = $$v;
      },
      expression: "category"
    }
  }), _c('shop-category-seo', {
    attrs: {
      "disabled": !((_vm$category3 = _vm.category) !== null && _vm$category3 !== void 0 && _vm$category3._id)
    },
    model: {
      value: _vm.category,
      callback: function ($$v) {
        _vm.category = $$v;
      },
      expression: "category"
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "md": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "color": "grey darken-1",
      "disabled": !((_vm$category4 = _vm.category) !== null && _vm$category4 !== void 0 && _vm$category4._id) || _vm.category.root ? true : false
    },
    on: {
      "click": function ($event) {
        return _vm.remove(_vm.category);
      }
    }
  }, [_vm._v("삭제")])], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "md": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "disabled": !_vm.category._id
    },
    on: {
      "click": function ($event) {
        return _vm.update(_vm.category);
      }
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }