<template>
    <v-row>
        <v-col cols="auto" class="pr-0"> <image-popup v-bind="{ src, size }" /> </v-col>
        <v-col> <v-file-input v-model="file" v-bind="{ ...$attrs, accept }" @change="emit" /> </v-col>
    </v-row>
</template>

<script>
import ImagePopup from "./image-popup.vue";

export default {
    components: {
        ImagePopup,
    },
    props: {
        value: { type: [File, Object], default: null },

        size: { type: [String, Number], default: "40" },
        accept: { type: String, default: "image/*" },
    },
    computed: {
        src() {
            if (this.file instanceof File) return URL.createObjectURL(this.file);
            else return this.value?.url;
        },
    },
    data: () => ({
        file: null,
    }),
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.init();
        },
    },
    methods: {
        init() {
            this.file = this.value;
        },
        emit() {
            console.log(this.file.name);
            this.$emit("input", this.file);
        },
    },
};
</script>

<style></style>
