<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%" height="100%">
            <list-heading title="회원 목록" v-model="showsSearch" showsFilterButton />
            <user-list-search v-bind="{ showsSearch }" />
            <v-row class="mt-n3">
                <v-spacer></v-spacer>
                <v-col cols="auto">
                    <v-sheet outlined rounded>
                        <v-btn color="white green--text" class="px-2" @click="excel">
                            <span> <v-icon>mdi-microsoft-excel</v-icon> 엑셀다운로드 </span>
                        </v-btn>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-data-table :headers="headers" :items="users" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.title`]="{ item, value }">
                    <edit-dialog-user-type :value="item" v-on="{ updateItem }"> {{ value }} </edit-dialog-user-type>
                </template>
                <template #[`item.isWarning`]="{ item }">
                    <v-switch v-model="item.isWarning" v-bind="attrs_switch" @change="(isWarning) => update({ _id: item._id, isWarning })" />
                </template>
                <template #[`item.orderPrice`]="{ item }">
                    <span v-if="item.orderPrice">{{ item.orderPrice.format() }}</span>
                    <span v-else>-</span>
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-btn small text icon tile :to="`${$route.path}/${item._id}`">
                        <v-icon small> mdi-pencil </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import { attrs_switch } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import UserListSearch from "@/components/console/users/user-list-search.vue";
import EditDialogUserType from "@/components/console/users/edit-dialog-user-type.vue";

const headers = [
    { width: 100, text: "유형", value: "title", divider: true },
    { width: 160, text: "아이디", value: "username", divider: true },
    { width: 160, text: "이름", value: "name", divider: true },
    { width: 160, text: "연락처", value: "phone", divider: true, formatter: (value) => value?.phoneNumberFormat?.() || value || "-" },
    { width: 160, text: "이메일", value: "email", divider: true },
    { width: 140, text: "우대회원등급", value: "level.name", divider: true },
    { width: 100, text: "주의회원", value: "isWarning", divider: true },
    { width: 120, text: "상품구매금액", value: "orderPrice", divider: true },
    { width: 170, text: "가입일자", value: "createdAt", divider: true, formatter: (value) => value?.toDateTime?.() || value || "-" },
    { width: +60, text: "", value: "actions", align: "center" },
].map((item) => ({ ...item, formatter: item?.formatter || ((value) => value || "-") }));

export default {
    components: {
        ListHeading,
        UserListSearch,
        EditDialogUserType,
    },
    data: () => ({
        users: [],

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,
        showsSearch: true,

        headers,
        attrs_switch,
    }),
    computed: {
        items() {
            return this.users.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            delete query.page;

            return { ...query };
        },
    },
    mounted() {
        this.init().then(this.search);
    },
    watch: {
        params() {
            this.init().then(this.search);
        },
    },
    methods: {
        async init() {
            try {
                this.users = [];
                this.summary = { totalCount: 0 };
            } catch (error) {
                this.$handleError(error);
            }
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { skip, limit, params } = this;
                const { summary, users } = await api.console.users.gets({ headers: { skip, limit }, params });

                this.users = users;
                this.summary = summary;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        async update(user) {
            try {
                await api.console.users.put(user);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        updateItem(user) {
            const index = this.users.findIndex(({ _id }) => _id == user._id);
            if (0 <= index) this.users.splice(index, 1, user);
            else {
                this.users = [user, ...this.users];
                this.summary.totalCount += 1;
            }
            this.search();
        },

        async excel() {
            try {
                var { users } = await api.console.users.gets({
                    filter: this.filter,
                });

                var rows = [];
                users.forEach((user) => {
                    rows.push({
                        "사용자 이름": user.name,
                        연락처: user.phone,
                        이메일: user.email,
                        회원등급: user.level.name ? user.level.name : "-",
                        주의회원여부: user.isWarning ? "주의" : "-",
                        상품구매금액: 0,
                        가입일자: user.createdAt.toDateTime(),
                    });
                });

                var workbook = new XLSX.utils.book_new();
                var worksheet = XLSX.utils.json_to_sheet(rows);

                XLSX.utils.book_append_sheet(workbook, worksheet, "new");
                XLSX.writeFile(workbook, "회원목록.xlsx");
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>
