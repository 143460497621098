<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-product-view">
            <div v-if="!$route.params._product" class="headline text-start mt-8">상품 등록</div>
            <div v-else class="headline text-start mt-8">상품 상세</div>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1">카테고리</v-card-title>
                <v-card-text>
                    <shop-category-select v-model="product.category" :categories="categories" @input="product._category = product.category._id"></shop-category-select>
                </v-card-text>
            </v-card>

            <v-row class="mt-4">
                <v-col cols="12" md="7" sm="7">
                    <v-card>
                        <v-card-title class="subtitle-1">썸네일</v-card-title>
                        <v-card-text>
                            <v-row align="center">
                                <v-col cols="auto" class="py-0">
                                    <v-img v-if="thumb || product.thumb" :src="thumb ? createObjectURL(thumb) : `/res/shop/products/${$route.params._product}/${product.thumb}`" width="128" height="128"></v-img>
                                    <v-responsive v-else width="128" height="128">
                                        <v-row class="fill-height ma-0 grey lighten-3" align="center" justify="center">
                                            <v-icon>mdi-image</v-icon>
                                        </v-row>
                                    </v-responsive>
                                </v-col>
                                <v-col class="py-0">
                                    <v-file-input v-model="thumb" label="썸네일 업로드" show-size accept="image/*"></v-file-input>
                                </v-col>
                                <v-col v-if="thumb || product.thumb" cols="auto" class="py-0 pl-0">
                                    <v-btn icon @click="removeThumb"><v-icon>mdi-delete</v-icon></v-btn>
                                </v-col>
                            </v-row>
                            <v-row> </v-row>
                        </v-card-text>
                    </v-card>

                    <v-card class="mt-4">
                        <v-card-title class="subtitle-1">상품 이미지</v-card-title>
                        <v-card-text>
                            <draggable v-model="images">
                                <transition-group>
                                    <v-row v-for="(i, index) in images" :key="`image-${index}`" class="pt-0 pb-0" align="center">
                                        <v-col cols="auto" class="pt-4 pb-0">
                                            <v-img v-if="images[index]" :src="createObjectURL(images[index])" width="128" height="128"></v-img>
                                            <v-responsive v-else width="128" height="128">
                                                <v-row class="fill-height ma-0 grey lighten-3" align="center" justify="center">
                                                    <v-icon>mdi-image</v-icon>
                                                </v-row>
                                            </v-responsive>
                                        </v-col>
                                        <v-col>
                                            <v-file-input v-model="images[index]" :label="'슬라이드' + (+index + 1)" show-size dense accept="image/*"></v-file-input>
                                            <!-- <v-btn v-if="images[index] || (product.images[index])" dense color="white" class="red--text"><v-icon class="mr-4">mdi-delete</v-icon>삭제</v-btn> -->
                                        </v-col>
                                        <v-col v-if="images[index] || product.images[index]" cols="auto" class="py-0 pl-0">
                                            <v-btn icon @click="removeImage(index)"><v-icon>mdi-delete</v-icon></v-btn>
                                        </v-col>
                                    </v-row>
                                </transition-group>
                            </draggable>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="5" sm="5">
                    <v-card>
                        <v-card-title class="subtitle-1">상품 정보</v-card-title>
                        <v-card-text>
                            <v-text-field v-model="product.name" label="상품명" persistent-placeholder></v-text-field>
                            <v-text-field v-model="product.price" label="판매가 (0원 일때 '무료'로 문구변경)" persistent-placeholder></v-text-field>
                            <v-text-field v-model="product.stock" label="재고" persistent-placeholder :disabled="product.optionsEnabled"></v-text-field>
                            <v-text-field v-show="false" v-model="product.cost" label="원가 (0원 이상시 할인율도 같이표시)" persistent-placeholder></v-text-field>
                            <v-text-field v-model="product.sequence" label="진열순서" persistent-placeholder></v-text-field>
                            <v-text-field v-model="product.manufacturer" label="제조사" persistent-placeholder></v-text-field>
                            <v-text-field v-model="product.origin" label="원산지" persistent-placeholder></v-text-field>
                            <v-row>
                                <v-col>
                                    <v-text-field v-model="hashtag" label="해시태그" persistent-placeholder dense hide-details @keydown.enter="(product.hashtags.push(hashtag) || true) && (hashtag = null)"></v-text-field>
                                </v-col>
                                <v-col cols="auto" class="pl-0">
                                    <v-btn
                                        color="primary"
                                        small
                                        @click="
                                            product.hashtags.push(hashtag);
                                            hashtag = null;
                                        "
                                        ><v-icon small class="mr-2">mdi-tag</v-icon>추가</v-btn
                                    >
                                </v-col>
                                <v-col cols="12" class="py-0">
                                    <v-chip v-for="(tag, index) in product.hashtags || []" :key="index" close class="mr-1" close-icon="mdi-delete" @click:close="product.hashtags.splice(index, 1)">{{ tag }}</v-chip>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <!-- <v-card class="mt-4">
                                <v-card-title>
                                    <v-row>
                                        <v-col cols="auto" class="subtitle-1 py-0">브랜드</v-col>
                                        <v-spacer></v-spacer>
                                        <v-col cols="12" sm="12" md="6" class="py-0">
                                            <v-select v-model="product._brand" :items="brands" item-text="name" item-value="_id" dense hide-details class="mt-0"></v-select>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                            </v-card> -->

                    <v-card class="mt-4">
                        <v-card-title>
                            <v-row>
                                <v-col class="subtitle-1 py-0">전시여부 (전시/숨김)</v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto" class="py-0">
                                    <v-switch v-model="product.display" dense hide-details class="mt-0"></v-switch>
                                </v-col>
                            </v-row>
                        </v-card-title>
                    </v-card>

                    <v-card class="mt-4">
                        <v-card-title>
                            <v-row>
                                <v-col cols="auto" class="subtitle-1 py-0">판매여부 (판매/중단)</v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto" class="py-0">
                                    <v-switch v-model="product.sale" dense hide-details class="mt-0"></v-switch>
                                </v-col>
                            </v-row>
                        </v-card-title>
                    </v-card>

                    <v-card class="mt-4">
                        <v-card-title>
                            <v-row>
                                <v-col class="subtitle-1 py-0">신상품 (사용/미사용)</v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto" class="py-0">
                                    <v-switch v-model="product.newly" dense hide-details class="mt-0"></v-switch>
                                </v-col>
                            </v-row>
                        </v-card-title>
                    </v-card>

                    <!-- <v-card class="mt-4">
                                <v-card-title>
                                    <v-row>
                                        <v-col class="subtitle-1 py-0">특가 (사용/미사용)</v-col>
                                        <v-spacer></v-spacer>
                                        <v-col cols="auto" class="py-0">
                                            <v-switch v-model="product.special" dense hide-details class="mt-0"></v-switch>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                            </v-card> -->

                    <v-card class="mt-4">
                        <v-card-title>
                            <v-row>
                                <v-col class="subtitle-1 py-0">베스트 (사용/미사용)</v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto" class="py-0">
                                    <v-switch v-model="product.best" dense hide-details class="mt-0"></v-switch>
                                </v-col>
                            </v-row>
                        </v-card-title>
                    </v-card>

                    <v-card class="mt-4">
                        <v-card-title>
                            <v-row>
                                <v-col class="subtitle-1 py-0">품절여부 (품절/판매)</v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto" class="py-0">
                                    <v-switch v-model="product.soldout" dense hide-details class="mt-0"></v-switch>
                                </v-col>
                            </v-row>
                        </v-card-title>
                    </v-card>

                    <shop-product-grade v-model="product" />
                    <shop-product-bonus v-model="product" />
                </v-col>
            </v-row>

            <!-- 할인가 -->
            <shop-product-discount v-model="product" />

            <!-- 상품옵션 -->
            <shop-product-options v-model="product" />

            <!-- 추가상품 -->
            <shop-product-supplies v-model="product" />

            <!-- 배송비 -->
            <shop-product-shippings v-model="product" />

            <!-- 도서산간 -->
            <shop-product-islands v-model="product" />

            <!-- 검색엔진최적화(SEO) -->
            <shop-product-seo v-model="product" />

            <!-- 정기배송 -->
            <shop-product-regular-delivery v-model="product" />

            <v-card class="mt-6">
                <v-card-title class="subtitle-1">상품 상세 설명</v-card-title>
                <v-card-text>
                    <naver-smarteditor v-model="product.content" rows="10"></naver-smarteditor>
                </v-card-text>
            </v-card>

            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

import Draggable from "vuedraggable";
import ShopProductSeo from "@/components/console/shop/products/shop-product-seo.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import ShopProductBonus from "@/components/console/shop/products/shop-product-bonus.vue";
import ShopProductGrade from "@/components/console/shop/products/shop-product-grade.vue";
import ShopProductOptions from "@/components/console/shop/products/shop-product-options.vue";
import ShopCategorySelect from "@/components/console/shop/categories/shop-category-select.vue";
import ShopProductIslands from "@/components/console/shop/products/shop-product-islands.vue";
import ShopProductDiscount from "@/components/console/shop/products/shop-product-discount.vue";
import ShopProductSupplies from "@/components/console/shop/products/shop-product-supplies.vue";
import ShopProductShippings from "@/components/console/shop/products/shop-product-shippings.vue";
import ShopProductRegularDelivery from "@/components/console/shop/products/shop-product-regular-delivery.vue";

export default {
    components: {
        Draggable,
        ShopProductSeo,
        NaverSmarteditor,
        ShopProductBonus,
        ShopProductGrade,
        ShopProductOptions,
        ShopCategorySelect,
        ShopProductIslands,
        ShopProductDiscount,
        ShopProductSupplies,
        ShopProductShippings,
        ShopProductRegularDelivery,
    },
    data: () => {
        return {
            ready: false,
            tab: "basic",

            hashtag: null,

            ///////////////////////////////////////////////
            // 상품정보
            ///////////////////////////////////////////////
            product: {
                name: null,
                code: null,
                price: 0,
                cost: 0,
                stock: 0,
                sequence: 0,
                hashtags: [],
                thumb: null,
                images: new Array(5),
                content: null,

                display: true,
                sale: true,
                best: false,
                newly: false,
                special: false,
            },

            ///////////////////////////////////////////////
            // UI
            ///////////////////////////////////////////////
            categories: [],
            brands: [],
            thumb: null,
            images: new Array(5),
            hashtags: "",
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                var { categories } = await api.console.shop.categories.getCategories();
                this.categories = categories;

                var { brands } = await api.console.shop.brands.getBrands();
                this.brands = brands;

                if (this.$route.params._product) {
                    var { product } = await api.console.shop.products.getProduct({ _id: this.$route.params._product });
                    this.product = product;

                    if (this.$route.query.method == "copy") {
                        product._id = undefined;
                        product.autocomplete._id = undefined;

                        for (var i in product.options) {
                            product.options[i]._id = undefined;
                        }
                        for (var i in this.product.supplies) {
                            product.supplies[i]._id = undefined;
                        }
                    }
                }

                if (this.product.thumb) {
                    this.thumb = await api.console.shop.products.getResource(this.$route.params._product, this.product.thumb);
                    product.thumb = undefined;
                }

                for (var i in this.product.images) {
                    if (this.product.images[i]) {
                        this.images[i] = await api.console.shop.products.getResource(this.$route.params._product, this.product.images[i]);
                        product.images[i] = undefined;
                    }
                }

                if (this.product._id === undefined) {
                    var { summary } = await api.console.shop.products.getProducts({ headers: { limit: 1 } });
                    this.product.sequence = summary.totalCount;
                }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save() {
            try {
                this.product.optionsEnabled = 0 < (this.product.options || []).length;
                this.product.suppliesEnabled = 0 < (this.product.supplies || []).length;

                ////////////////////////////////////////////////////////////////
                // 상품저장
                ////////////////////////////////////////////////////////////////
                const { postProduct, putProduct } = api.console.shop.products;
                var { product } = await (this.product._id ? putProduct : postProduct)(this.product);

                ////////////////////////////////////////////////////////////////
                // 옵션저장
                ////////////////////////////////////////////////////////////////
                for (var i in this.product.options) {
                    var option = this.product.options[i];

                    if (option.deleted) {
                        await api.console.shop.products.options.deleteOption(product._id, option);
                    } else if (option._id) {
                        await api.console.shop.products.options.putOption(product._id, option);
                    } else if (!option._id) {
                        await api.console.shop.products.options.postOption(product._id, option);
                    }
                }

                ////////////////////////////////////////////////////////////////
                // 추가상품저장
                ////////////////////////////////////////////////////////////////
                for (var i in this.product.supplies) {
                    var supply = this.product.supplies[i];

                    if (supply.deleted) {
                        await api.console.shop.products.supplies.deleteSupply(product._id, supply);
                    } else if (supply._id) {
                        await api.console.shop.products.supplies.putSupply(product._id, supply);
                    } else if (!supply._id) {
                        await api.console.shop.products.supplies.postSupply(product._id, supply);
                    }
                }

                ////////////////////////////////////////////////////////////////
                // 리소스 저장
                ////////////////////////////////////////////////////////////////
                if (this.thumb) await api.console.shop.products.postThumb(product._id, this.thumb);
                for (var i in this.images) {
                    if (this.images[i]) await api.console.shop.products.postImage(product._id, i, this.images[i]);
                }

                alert(this.product._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        async removeThumb() {
            try {
                if (confirm("상품 썸네일을 삭제하시겠습니까?")) {
                    if (this.product._id) await api.console.shop.products.deleteThumb(this.product._id);

                    this.thumb = null;
                    this.product.thumb = null;
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        async removeImage(index) {
            try {
                if (confirm("상품 이미지를 삭제하시겠습니까?")) {
                    if (this.product._id) await api.console.shop.products.deleteImage(this.product._id, index);

                    this.images[index] = null;
                    this.product.images[index] = undefined;
                    this.$forceUpdate();
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {}
        },
    },
    computed: {
        optionsStock() {
            var stock = 0;
            for (var i in this.product.options) {
                stock += this.product.options[i].stock;
            }
            return stock;
        },
    },
    watch: {
        hashtags() {
            if (this.hashtags) {
                var hashtags = this.hashtags.split(",");
                for (var i in hashtags) {
                    hashtags[i] = hashtags[i].trim();
                }
                this.product.hashtags = hashtags;
            } else this.product.hashtags = [];
        },
        optionsStock() {
            if (this.product.optionsEnabled) {
                this.product.stock = this.optionsStock;
            }
        },
    },
};
</script>

<style>
.shop-product-view .weight input {
    text-align: end;
}
.shop-product-view .amount input {
    text-align: end;
}
.shop-product-view .theme--light.v-tabs-items {
    background-color: transparent !important;
}
.shop-product-view .option-groups-headers th {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>
