var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1440px",
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-8 mb-2",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pt-0 pb-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("띠배너 관리")]), _c('v-spacer')], 1), _c('v-data-table', {
    staticClass: "elevation-1 mt-4",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.items,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: `item.imagePc`,
      fn: function (_ref) {
        var _item$imagePc;
        var item = _ref.item;
        return [_c('v-col', {
          staticClass: "px-0"
        }, [_c('image-popup', {
          attrs: {
            "src": item === null || item === void 0 ? void 0 : (_item$imagePc = item.imagePc) === null || _item$imagePc === void 0 ? void 0 : _item$imagePc.url,
            "width": "80",
            "height": "60"
          }
        })], 1)];
      }
    }, {
      key: `item.imageMb`,
      fn: function (_ref2) {
        var _item$imageMb;
        var item = _ref2.item;
        return [_c('v-col', {
          staticClass: "px-0"
        }, [_c('image-popup', {
          attrs: {
            "src": item === null || item === void 0 ? void 0 : (_item$imageMb = item.imageMb) === null || _item$imageMb === void 0 ? void 0 : _item$imageMb.url,
            "width": "80",
            "height": "60"
          }
        })], 1)];
      }
    }, {
      key: `item.display.enabled`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-switch', _vm._b({
          on: {
            "change": function (enabled) {
              return _vm.save({
                _id: item._id,
                display: Object.assign({}, item.display, {
                  enabled
                })
              });
            }
          },
          model: {
            value: item.display.enabled,
            callback: function ($$v) {
              _vm.$set(item.display, "enabled", $$v);
            },
            expression: "item.display.enabled"
          }
        }, 'v-switch', _vm.switchAttrs, false))];
      }
    }, {
      key: `item.display.hasPeriod`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-switch', _vm._b({
          on: {
            "change": function (hasPeriod) {
              return _vm.save({
                _id: item._id,
                display: Object.assign({}, item.display, {
                  hasPeriod
                })
              });
            }
          },
          model: {
            value: item.display.hasPeriod,
            callback: function ($$v) {
              _vm.$set(item.display, "hasPeriod", $$v);
            },
            expression: "item.display.hasPeriod"
          }
        }, 'v-switch', _vm.switchAttrs, false))];
      }
    }, {
      key: `item.display.startedAt`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('span', {
          staticStyle: {
            "white-space": "pre-line"
          }
        }, [_vm._v(" " + _vm._s(item.display.startedAt ? item.display.startedAt.split(" ").join("\n") : "-") + " ")])];
      }
    }, {
      key: `item.display.endedAt`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('span', {
          staticStyle: {
            "white-space": "pre-line"
          }
        }, [_vm._v(" " + _vm._s(item.display.endedAt ? item.display.endedAt.split(" ").join("\n") : "-") + " ")])];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('dialog-input', {
          attrs: {
            "value": item
          },
          on: {
            "input": _vm.sync
          }
        }), _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_vm._v(" mdi-delete ")])];
      }
    }], null, true)
  }), _c('v-pagination', {
    staticClass: "mt-4 mb-12",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  }), _c('dialog-input', {
    attrs: {
      "isCreate": ""
    },
    on: {
      "input": function (item) {
        return _vm.sync(item, true);
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }