<template>
    <v-card class="mt-6 shop-product-supplies">
        <v-card-title>
            <v-row>
                <v-col class="subtitle-1 py-0">추가상품 (사용/미사용)</v-col>
                <v-col md="auto" sm="auto" class="py-0">
                    <v-switch v-model="suppliesEnabled" dense hide-details class="mt-0" @change="input"></v-switch>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text v-if="suppliesEnabled">
            <v-row align="center">
                <v-col cols="12" sm="2" class="py-0">옵션명 개수</v-col>
                <v-col cols="12" sm="10" class="py-0">
                    <v-select v-model="suppliesCount" :items="suppliesCountHeaders" item-text="text" item-value="value" dense hide-details @change="input"></v-select>
                </v-col>
            </v-row>
            <v-divider class="mt-4"></v-divider>
            <v-row align="center" class="mt-4">
                <v-col cols="12" sm="2" class="py-0">정렬순서</v-col>
                <v-col cols="12" sm="10" class="py-0">
                    <v-select v-model="suppliesSort" :items="suppliesSortHeaders" item-text="text" item-value="value" dense hide-details @change="input"></v-select>
                </v-col>
            </v-row>
            <v-divider class="mt-4"></v-divider>
            <v-row align="center" class="mt-4">
                <v-col cols="12" sm="2" class="py-0">옵션입력</v-col>
                <v-col cols="12" sm="10" class="py-0">
                    <v-row>
                        <v-col>추가상품명</v-col>
                        <v-col>추가상품값</v-col>
                        <v-col>추가상품가</v-col>
                        <v-col cols="2">
                        </v-col>
                    </v-row>
                    <v-row v-for="(i, index) in suppliesCount" :key="i">
                        <v-col>
                            <v-text-field v-model="suppliesGroups[index].name" dense hide-details></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="suppliesGroups[index].value" dense hide-details></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="suppliesGroups[index].price" dense hide-details @input="suppliesGroups[index].price = suppliesGroups[index].price.replace(/[^0-9,]/,'')"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-btn v-if="1 < suppliesCount" color="white" small class="px-1 mr-1 primary--text" style="min-width: auto" @click="suppliesCount--"><v-icon>mdi-minus</v-icon></v-btn>
                            <v-btn v-if="i == suppliesCount && i < suppliesCountHeaders.length" color="primary" small class="px-1" style="min-width: auto" @click="suppliesCount++"><v-icon>mdi-plus</v-icon></v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="auto">
                            <v-btn color="primary" dark small @click="generate(); input();">옵션목록으로 적용<v-icon small class="ml-2">mdi-chevron-down</v-icon></v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider class="mt-4"></v-divider>
            <v-row class="mt-4">
                <v-col cols="12" sm="2">옵션목록</v-col>
                <v-col cols="12" sm="10" class="py-0">
                    <v-row>
                        <v-spacer></v-spacer>
                        <v-col cols="2" md="1" class="px-1">
                            <v-select v-model="header.operator" :items="operatorItems" label="연산자" persistent-placeholder dense hide-details></v-select>
                        </v-col>
                        <v-col cols="4" md="2" class="px-1">
                            <v-text-field v-model="header.price" label="추가상품가" persistent-placeholder dense hide-details @input="header.price = header.price.replace(/[^0-9]/,'')"></v-text-field>
                        </v-col>
                        <v-col cols="4" md="2" class="px-1">
                            <v-text-field v-model="header.stock" label="재고수량" persistent-placeholder dense hide-details @input="header.stock = header.stock.replace(/[^0-9]/,'')"></v-text-field>
                        </v-col>
                        <v-col cols="2" md="1" class="px-1">
                            <v-select v-model="header.enabled" :items="enabledItems" label="사용여부" persistent-placeholder dense hide-details></v-select>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn color="secondary" small @click="modify">선택목록 일괄수정</v-btn>
                        </v-col>
                    </v-row>
                    <v-data-table v-model="selected" :headers="suppliesHeaders" :items="supplies.filter((supply) => !supply.deleted)" show-select>
                        <template v-slot:item.name="{ item }">
                            {{ item.name.split(':')[0] }}
                        </template>
                        <template v-slot:item.price="{ item }">
                            <v-text-field v-model="item.price" dense hide-details class="price"></v-text-field>
                        </template>
                        <template v-slot:item.stock="{ item }">
                            <v-text-field v-model="item.stock" dense hide-details class="price"></v-text-field>
                        </template>
                        <template v-slot:item.enabled="{ item }">
                            <v-simple-checkbox v-model="item.enabled" dense color="primary"></v-simple-checkbox>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-icon small @click="remove(item)">mdi-delete</v-icon>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default{
    props: {
        value: { type: Object, default: null }
    },
    data: () => {
        return {
            suppliesEnabled: false,
            suppliesSort: "byLatest",
            suppliesSortHeaders: [
                { text: "등록일자순", value: "byLatest" },
                { text: "이름순", value: "byName" },
                { text: "높은가격순", value: "byHighPrice" },
                { text: "낮은가격순", value: "byLowPrice" }
            ],

            suppliesCount: 1,
            suppliesCountHeaders: [
                { text: "1개", value: 1 },
                { text: "2개", value: 2 },
                { text: "3개", value: 3 },
                { text: "4개", value: 4 },
                { text: "5개", value: 5 }
            ],

            suppliesGroups: [{ name: '', value: '', price: '' }],

            supplies: [],
            suppliesHeaders: [
                {
                    text: '추가상품명',
                    align: 'center',
                    sortable: false,
                    value: 'name'
                },
                {
                    text: '추가상품값',
                    align: 'center',
                    width: 140,
                    value: 'value'
                },
                {
                    text: '추가상품가',
                    align: 'center',
                    width: 140,
                    value: 'price'
                },
                {
                    text: '재고수량',
                    align: 'center',
                    width: 140,
                    value: 'stock'
                },
                {
                    text: '사용여부',
                    align: 'center',
                    width: 100,
                    sortable: false,
                    value: 'enabled'
                },
                {
                    text: 'actions',
                    align: 'center',
                    width: 100,
                    sortable: false,
                    value: 'actions'
                }
            ],
            selected: [],

            header: {
                operator: 1,
                price: null,
                stock: null,
                enabled: true
            },

            operatorItems: [
                { text: '+', value: 1 },
                { text: '-', value: -1 }
            ],

            enabledItems: [
                { text: 'Y', value: true },
                { text: 'N', value: false }
            ]
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            var { suppliesEnabled, suppliesSort, supplies } = this.value;

            if(suppliesEnabled === undefined) suppliesEnabled = this.suppliesEnabled;
            if(suppliesSort === undefined) suppliesSort = this.suppliesSort;
            if(supplies === undefined) supplies = this.supplies;

            Object.assign(this.$data, {
                suppliesEnabled,
                suppliesSort,
                supplies
            });
        },

        input(){
            this.$set(this.value, 'suppliesEnabled', this.suppliesEnabled);
            this.$set(this.value, 'suppliesSort', this.suppliesSort);
            this.$set(this.value, 'supplies', this.supplies);

            this.$emit('input', this.value);
        },

        remove(supply){
            if(supply._id) this.$set(supply, "deleted", true);
            else this.supplies.splice(this.supplies.indexOf(supply), 1);
        },

        generate(){
            while(0 < this.supplies.filter((supply) => !supply.deleted).length){
                this.supplies.filter((supply) => !supply.deleted).forEach((supply) => this.remove(supply));
            }
            this.selected = [];
            this.supplies = [];

            for(var i=0; i<this.suppliesCount; i++){
                var group = this.suppliesGroups[i];
                var values = group.value.split(',');
                var prices = group.price.split(',');

                for(var j in values){
                    this.supplies.push({
                        name: group.name + ': ' + values[j],
                        value: values[j],
                        price: prices[j] ? prices[j] : 0,
                        stock: 0,
                        enabled: true
                    });
                }
            }
        },

        modify(){
            for(var i in this.selected){
                if(!Number.isNaN(parseInt(this.header.price))) this.selected[i].price = +this.selected[i].price + parseInt(this.header.price || 0) * this.header.operator;
                if(!Number.isNaN(parseInt(this.header.stock))) this.selected[i].stock = +this.selected[i].stock + parseInt(this.header.stock || 0) * this.header.operator;
                this.selected[i].enabled = this.header.enabled;
            }
            this.input();
        }
    },
    watch: {
        suppliesCount(){
            this.suppliesGroups.splice(this.suppliesCount, this.suppliesGroups.length - this.suppliesCount);
            for(var i=0; i < this.suppliesCount; i++){
                if(!this.suppliesGroups[i]) this.$set(this.suppliesGroups, i, { name: '', value: '', price: '' });
            }
        }
    }
}
</script>

<style>
.shop-product-supplies .price input[type=text] { text-align: center; }
</style>
