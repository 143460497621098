var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "480",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', _vm._b({
    attrs: {
      "light": ""
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-spacer'), _vm._v(" "), _c('v-icon', {
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    staticClass: "mx-n2 py-2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('autocomplete-user', _vm._b({
    ref: "autocomplete-user",
    attrs: {
      "label": "신청자",
      "params": _vm.params_autocompleteUser
    },
    model: {
      value: _vm.form._user,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_user", $$v);
      },
      expression: "form._user"
    }
  }, 'autocomplete-user', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading,
    disabled: !_vm.isCreate
  }), false))], 1), _c('v-col', {
    staticClass: "px-2 py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-rrn-field', _vm._b({
    staticClass: "mx-n2",
    attrs: {
      "label": "주민등록번호",
      "class__col": "pa-2"
    },
    model: {
      value: _vm.form.residentRegistrationNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "residentRegistrationNumber", $$v);
      },
      expression: "form.residentRegistrationNumber"
    }
  }, 'v-rrn-field', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading,
    readonly: !_vm.isCreate
  }), false))], 1), _c('v-col', {
    staticClass: "px-2 py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-bank-field', _vm._b({
    staticClass: "mx-n2",
    attrs: {
      "class__row": "my-n2",
      "class__col": "pa-2",
      "showsLabel": ""
    },
    model: {
      value: _vm.form.bank,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bank", $$v);
      },
      expression: "form.bank"
    }
  }, 'v-bank-field', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading,
    readonly: !_vm.isCreate
  }), false))], 1)], 1)], 1), _c('v-card-actions', [_vm.mode == 'form' ? [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-switch', _vm._b({
    attrs: {
      "dense": ""
    },
    model: {
      value: _vm.form.isActive,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isActive", $$v);
      },
      expression: "form.isActive"
    }
  }, 'v-switch', _vm.attrs_switch, false), [_c('span', {
    staticClass: "subtitle-2",
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v(" " + _vm._s(_vm.form.isActive ? "활성화" : "비활성화") + " ")])])], 1), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])] : _vm._e()], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }