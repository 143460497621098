var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "subtitle-1 py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("특정 등급 회원노출 (사용/미사용)")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "value": true,
      "dense": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.form.isGrade,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isGrade", $$v);
      },
      expression: "form.isGrade"
    }
  })], 1)], 1)], 1), _vm.form.isGrade ? _c('v-card-text', [_c('v-select', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "multiple": "",
      "items": _vm.grades,
      "item-text": "name",
      "item-value": "_id",
      "placeholder": "회원 등급을 선택해 주세요.",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form._grade,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_grade", $$v);
      },
      expression: "form._grade"
    }
  })], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }