<template>
    <v-card v-if="value" class="mt-6 shop-product-supplies">
        <v-card-title>
            <v-row>
                <v-col class="subtitle-1 py-0">정기배송 (사용/미사용)</v-col>
                <v-col md="auto" sm="auto" class="py-0">
                    <v-switch v-model="regularDeliveryEnabled" dense hide-details class="mt-0" @change="input"></v-switch>
                </v-col>
            </v-row>
        </v-card-title>
    </v-card>
</template>

<script>
export default {
    props: {
        value: { type: Object, default: null }
    },
    data() {
        return {
            regularDeliveryEnabled: false,
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                
                var { regularDeliveryEnabled } = this.value;
                
                Object.assign(this.$data, {
                    regularDeliveryEnabled: regularDeliveryEnabled || false,
                });
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },
        input(){
            this.value.regularDeliveryEnabled = this.regularDeliveryEnabled;

            this.$emit('input', { ...this.value });
        }

    }
}
</script>
