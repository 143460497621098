<template>
    <v-dialog v-model="shows" max-width="500px">
        <template #activator="{ attrs, on }">
            <v-btn small outlined color="primary" class="mr-2" v-bind="{ ...attrs, loading }" v-on="on">송장업로드</v-btn>
        </template>
        <v-card v-if="shows">
            <v-card-title>
                <span class="subtitle-1">송장 업로드</span>
                <v-spacer />
                <v-btn color="white" @click="$refs.excel.$refs.input.click()" class="green--text px-2"><v-icon class="mr-2">mdi-microsoft-excel</v-icon>엑셀업로드</v-btn>
                <v-file-input ref="excel" accept=".xls, .xlsx" class="d-none" @change="upload"></v-file-input>
            </v-card-title>
            <v-divider />
            <v-data-table v-bind="{ items, headers, loading }">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
            </v-data-table>
            <v-divider />
            <v-card-actions>
                <v-btn color="white" @click="download" class="secondary--text px-2"><v-icon class="mr-2">mdi-table-arrow-down</v-icon>양식다운로드</v-btn>
                <v-spacer />
                <v-btn color="grey" text @click="shows = false">취소</v-btn>
                <v-btn color="primary" text :disabled="!items.length" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";

const headers = [
    { width: 190, divider: true, text: "상품주문번호", value: "상품주문번호" },
    { width: 120, divider: true, text: "택배업체", value: "택배업체" },
    { width: 190, text: "운송장번호", value: "운송장번호" },
].map((item) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), cellClass: "caption" }));

export default {
    props: {
        loading: { type: Boolean, default: false },
        deliveries: { type: Array, default: () => [] },
    },
    data: () => ({
        items: [],
        headers,

        shows: false,
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.items = [];
        },
        download() {
            const book = XLSX.utils.book_new();
            const sheet = XLSX.utils.json_to_sheet([
                {
                    ["상품주문번호"]: null,
                    ["택배업체"]: null,
                    ["운송장번호"]: null,
                },
            ]);
            XLSX.utils.book_append_sheet(book, sheet);
            XLSX.writeFile(book, "송장업로드 양식.xlsx");
        },
        upload(file) {
            if (file) {
                let reader = new FileReader();
                reader.onload = () => {
                    var data = reader.result;
                    var items = [];
                    // let data = e.target.result;
                    var workbook = XLSX.read(data, {
                        type: "binary",
                    });
                    workbook.SheetNames.forEach((sheetName) => {
                        let roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                        items.push(roa);
                    });

                    this.items = items[0]; // 첫번째 시트
                };
                reader.readAsBinaryString(file);
            }
        },
        async save() {
            if (this.loading) return;
            else this.$emit("loading", true);

            try {
                for (var invoice of this.items) {
                    await api.console.shop.invoices.putInvoice({
                        purchaseNo: invoice["상품주문번호"],
                        delivery: {
                            _id: (this.deliveries.find((delivery) => delivery?.name == invoice["택배업체"]) || {})?._id,
                            method: "택배,등기,소포",
                            number: invoice["운송장번호"],
                        },
                    });
                }
                alert("변경되었습니다");
                this.shows = false;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.$emit("loading", false);
                this.$emit("search");
            }
        },
    },
};
</script>
