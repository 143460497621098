var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    staticClass: "v-sheet--outlined fill-height",
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "mobile-breakpoint": "10000",
      "disable-pagination": "",
      "disable-sort": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c('div', {
          staticClass: "table-label px-1 white"
        }, [_vm._v("결제정보")])];
      },
      proxy: true
    }, _vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.paymentMethod`,
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.paymentMethod) + " "), _c('order-view-account', _vm._b({
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref2) {
              var attrs = _ref2.attrs,
                on = _ref2.on;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "my-n3",
                attrs: {
                  "icon": "",
                  "text": "",
                  "small": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-information")])], 1)];
            }
          }])
        }, 'order-view-account', {
          order: _vm.order
        }, false))];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }