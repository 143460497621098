<template>
    <v-card class="mt-4">
        <v-card-title>
            <v-row>
                <v-col cols="auto" class="subtitle-1 py-0">보너스적립 (사용/미사용)</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="py-0">
                    <v-switch v-model="form.bonusEnabled" :value="true" dense hide-details class="mt-0" @change="emit"></v-switch>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text v-if="form.bonusEnabled">
            <v-currency-field v-model="form.bonus.amount" :max="100" label="적립퍼센트" suffix="%" class="text-align-end-input ma-0" persistent-placeholder persistent-hint :hint="`적립금액: ${bonusPrice?.format?.()}원`" @change="emit" />
        </v-card-text>
    </v-card>
</template>

<script>
import dayjs from "dayjs";

const initForm = (form = {}) => ({
    ...form,
    bonusEnabled: form?.bonusEnabled || false,
    bonus: {
        ...(form?.bonus || {}),
        unit: "percent",
        amount: form?.bonus?.amount || 0,
    },
});

export default {
    props: {
        value: { type: Object, default: initForm },
    },
    data: () => ({
        form: initForm(),
    }),
    computed: {
        discountPrice() {
            const { price, discount, discountEnabled } = this.value;
            if ((discountEnabled && !discount?.expiration?.enabled) || ((discount?.expiration?.startDate ? dayjs().isAfter(discount?.expiration?.startDate) : false) && (discount?.expiration?.endDate ? dayjs().isBefore(discount?.expiration?.endDate) : false))) {
                switch (discount?.unit) {
                    case "percent": {
                        return Math.floor((price * discount?.amount) / 100);
                    }
                    case "won": {
                        return discount?.amount;
                    }
                    default:
                        return 0;
                }
            }
            return 0;
        },
        salePrice() {
            return this.value?.price - this.discountPrice;
        },
        bonusPrice() {
            return Math.floor((this.salePrice / 1000) * this.form?.bonus?.amount || 0) * 10;
        },
    },
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            this.form = initForm(this.value);
        },
        emit() {
            const { bonusEnabled, bonus } = this.form;
            this.$emit("input", initForm({ ...this.value, bonusEnabled, bonus }));
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep {
    .text-align-end-input input {
        text-align: end;
    }
}
</style>
