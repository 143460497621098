var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "display",
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1280px",
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("전시관리")]), _c('v-tabs', {
    attrs: {
      "background-color": "content"
    },
    model: {
      value: _vm.tabIndex,
      callback: function ($$v) {
        _vm.tabIndex = $$v;
      },
      expression: "tabIndex"
    }
  }, [_c('v-tab', [_vm._v("섹션1")]), _c('v-tab', [_vm._v("섹션2")]), _c('v-tab', [_vm._v("섹션3")]), _c('v-tab', [_vm._v("섹션4")])], 1), _c('v-tabs-items', {
    attrs: {
      "touchless": ""
    },
    model: {
      value: _vm.tabIndex,
      callback: function ($$v) {
        _vm.tabIndex = $$v;
      },
      expression: "tabIndex"
    }
  }, _vm._l(_vm.codes, function (code) {
    return _c('v-tab-item', {
      key: code
    }, [_c('draggable', {
      staticClass: "d-flex flex-wrap",
      on: {
        "input": _vm.drag
      },
      model: {
        value: _vm.displays.filter[code],
        callback: function ($$v) {
          _vm.$set(_vm.displays.filter, code, $$v);
        },
        expression: "displays.filter[code]"
      }
    }, _vm._l(_vm.displays.filter[code], function (display) {
      var _display$product, _display$product2, _display$product3, _display$startDate, _display$startDate$to, _display$endDate, _display$endDate$toDa;
      return _c('v-col', {
        key: display._id,
        attrs: {
          "cols": "6",
          "sm": "3"
        }
      }, [_c('v-card', {
        staticClass: "d-flex flex-column",
        staticStyle: {
          "overflow": "hidden"
        },
        attrs: {
          "height": "30vh"
        }
      }, [_c('v-card-text', {
        staticClass: "pa-0",
        staticStyle: {
          "height": "90%"
        }
      }, [_c('v-img', {
        attrs: {
          "src": `/res/shop/products/${display === null || display === void 0 ? void 0 : (_display$product = display.product) === null || _display$product === void 0 ? void 0 : _display$product._id}/${display === null || display === void 0 ? void 0 : (_display$product2 = display.product) === null || _display$product2 === void 0 ? void 0 : _display$product2.thumb}`,
          "cover": "",
          "height": "80%"
        }
      }), _c('div', {
        staticClass: "pa-2 pb-0 font-weight-bold text-truncate"
      }, [_vm._v(_vm._s((display === null || display === void 0 ? void 0 : (_display$product3 = display.product) === null || _display$product3 === void 0 ? void 0 : _display$product3.name) || "-"))]), _c('div', {
        staticClass: "px-2"
      }, [display.startDate ? _c('span', [_vm._v(_vm._s((display === null || display === void 0 ? void 0 : (_display$startDate = display.startDate) === null || _display$startDate === void 0 ? void 0 : (_display$startDate$to = _display$startDate.toDate) === null || _display$startDate$to === void 0 ? void 0 : _display$startDate$to.call(_display$startDate)) || ""))]) : _vm._e(), _vm._v(" ~ "), display.endDate ? _c('span', [_vm._v(_vm._s((display === null || display === void 0 ? void 0 : (_display$endDate = display.endDate) === null || _display$endDate === void 0 ? void 0 : (_display$endDate$toDa = _display$endDate.toDate) === null || _display$endDate$toDa === void 0 ? void 0 : _display$endDate$toDa.call(_display$endDate)) || ""))]) : _vm._e()])], 1), _c('v-card-actions', {
        staticClass: "pt-0"
      }, [_c('v-spacer'), _c('v-icon', {
        staticClass: "mr-2",
        attrs: {
          "small": ""
        },
        on: {
          "click": function ($event) {
            return _vm.edit(display);
          }
        }
      }, [_vm._v("mdi-pencil")]), _c('v-icon', {
        staticClass: "mr-2",
        attrs: {
          "small": ""
        },
        on: {
          "click": function ($event) {
            return _vm.remove(display);
          }
        }
      }, [_vm._v("mdi-delete")])], 1)], 1)], 1);
    }), 1), !_vm.displays.filter[code].length ? _c('v-card', {
      staticClass: "mt-4"
    }, [_c('v-card-text', {
      staticClass: "text-center"
    }, [_vm._v("등록된 데이터가 없습니다")])], 1) : _vm._e()], 1);
  }), 1), _c('v-btn', {
    attrs: {
      "color": "primary",
      "fab": "",
      "fixed": "",
      "bottom": "",
      "right": ""
    },
    on: {
      "click": _vm.create
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1), _c('v-dialog', {
    attrs: {
      "max-width": "700"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_vm.editItem ? _c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("전시상품등록")]), _c('v-card-text', [!_vm.editItem._id ? _c('product-autocomplete', {
    staticClass: "mt-2",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": function (product) {
        return _vm.editItem._product = product._id;
      }
    },
    model: {
      value: _vm.editItem.product,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "product", $$v);
      },
      expression: "editItem.product"
    }
  }) : _vm._e(), _c('v-row', {
    staticClass: "mt-7"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-date-field', {
    attrs: {
      "label": "전시시작일자",
      "dense": "",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.editItem.startDate,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "startDate", $$v);
      },
      expression: "editItem.startDate"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-date-field', {
    attrs: {
      "label": "전시종료일자",
      "dense": "",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.editItem.endDate,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "endDate", $$v);
      },
      expression: "editItem.endDate"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        return _vm.close();
      }
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.save();
      }
    }
  }, [_vm._v("저장")])], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }